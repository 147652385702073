import React, { useState, useEffect } from "react"; // , useCallback    Component, useMemo, useRef // const { useState, useEffect, useRef } = React;
// import PropTypes from 'prop-types';
import SplitPane from "react-split-pane"; //, { Pane }
import { Auth } from "aws-amplify"; //Amplify, { Auth, Hub }
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CreatableSelect from "react-select/creatable"; // Creatable, { makeCreatableSelect }
import Select from "react-select";
import toast from "react-hot-toast";
// import XLSX from 'xlsx';
import * as XLSX from "xlsx";
import IgvVaNex from "../components/IgvVaNex";

// import DropdownButton from 'react-bootstrap/DropdownButton';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Button from 'react-bootstrap/Button';
import Dropdown from "react-bootstrap/Dropdown";
// import Modal from 'react-bootstrap/Modal';

import { Modal } from "bootstrap"; // Collapse, Popover, Toast, Tooltip, Alert, , Dropdown
import { Formik, Form, Field, FieldArray } from "formik"; //, ErrorMessage
import { Prompt } from "react-router"; // withRouter

// import DyLoP from '../DyLoP/DyLoP';
import OmimInfo from "./OmimInfo";
import VariantTable from "./VariantTable";
import VariantInfoBox from "./VariantInfoBox";
import {GetSignedURL, GetSampleRank, EditableTextArea, VanexNoteCell, 
  VanexTagCell, VanexReviewCell, ConstructFilterFunctions, VanexArrayCell, VanexMrPhenePhenoTermsCell, VanexSVGenotypeCell, VanexSVQUALCell,
  sortIntColumn,sortFloatColumn,VanexSegmentSizeCell, sortColumnByArrayLength, separateBy1000} from '../HelperFunctions' // VanexMrPhenePhenoTermsCell, 
import IndeterminateCheckbox, {CHECKED, UNCHECKED, INDETERMINATE} from "./IndeterminateCheckbox";


import variantFormatDefinition from "../resources/OutputDefinitionRareDiseaseGRCh38.json";
import filterDefinition from "../resources/filters.json";
// import pathways from '../resources/pathways.json';
// import { transferKeyToUpperCase } from '@aws-amplify/core';
import pako from "pako";
import ReviewOptions from "../resources/reviewOptions.json";

import Firefly from '@nch-igm/firefly';
// import Accordion from 'react-bootstrap/Accordion';
// import AccordionButton, { useAccordionButton } from 'react-bootstrap/AccordionButton';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap';

// console.log('Inside SvViewer')

/** //! duplicated code! is in RdApp too
 * @param {Object} object
 * @param {string} key
 * @return {any} value
 */
const getParameterCaseInsensitive = (object, key) => {
  return object[
    Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())
  ];
};

// var request;
const ENDPOINT_BASE = process.env.REACT_APP_API_URI;
// console.log('Inside SvViewer2')
const SvViewer = (props) => {
  //* this controls whether we want the user to change sample types (PMF) during the analysis by showing the button before the column names in the column list
  const showPMFPrefix =
    !!props.currentAnalysisInfo &&
    props.currentAnalysisInfo.ANALYSIS_TYPE &&
    (props.currentAnalysisInfo.ANALYSIS_TYPE === "GermlineSNV" ||
      props.currentAnalysisInfo.ANALYSIS_TYPE === "SomaticSNV")
      ? false
      : true;
  // const [showPMFPrefix, setShowPMFPrefix] = useState( (typeof props.setPMFPrefix == "boolean") ? props.setPMFPrefix : (!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE && (props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV')) ? false : true );
  // const [showPMFPrefix, setShowPMFPrefix] = useState( (typeof props.setPMFPrefix == "boolean") ? props.setPMFPrefix : true );
  const [use1000Separator, setUse1000Separator] = useState(true);
  const initialSortBy = [{id:"CHR_NUM", desc:false},{id:"ABS_SEG_MEAN", desc:true}];
  // const columnData = React.useMemo(() => {
  const usepacbio = props.usepacbio
  console.log(props)
  const [initialColumnData, setInitialColumnData] = useState(() => {
    if (!!props.dataColumns) return props.dataColumns;

    // if(!!props.caseData && props.caseData.length > 0 && !!props.caseData[0]){
    //   let columnKeys = new Set(Object.keys(props.caseData[0]));
    //   props.caseData.forEach(rowData =>{
    //     columnKeys = new Set([...columnKeys,...Object.keys(rowData)]);
    //   });
    //   return( Array.from(columnKeys).map(key => {
    if (!!props.caseData[0]) {
      return Object.keys(props.caseData[0]).map((key) => {
        if (key === "VANEX_TAGS") {
          return {
            Header: "Tag",
            id: key,
            accessor: (dt) => dt[key],
            width: 10,
            disableResizing: true,
            Cell: VanexTagCell,
            renderDefinedCell: true,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "VANEX_NOTES") {
          return {
            Header: "Note",
            id: key,
            accessor: (dt) => dt[key],
            width: 200,
            Cell: VanexNoteCell,
            renderDefinedCell: true,
            sortType: (rowA, rowB, columnId, desc) => {
              // TODO: sortType needs to return only 1 or -1. Make sure this is the case
              // if(rowA.values[columnId].CURRENT_USER_NOTE!=="" && !!rowB.values[columnId].CURRENT_USER_NOTE!=="")
              return rowA.values[columnId].CURRENT_USER_NOTE.localeCompare(
                rowB.values[columnId].CURRENT_USER_NOTE,
                undefined,
                { numeric: true }
              );
              // if(rowA.values[columnId].CURRENT_USER_NOTE==="" && rowB.values[columnId].CURRENT_USER_NOTE==="") return 100;
              // if(rowA.values[columnId].CURRENT_USER_NOTE==="") return 100;
              // return -100;
            },
            columnStyle: { textAlign: "center" },
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "VANEX_REVIEWS") {
          return {
            Header: "Review",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            Cell: VanexReviewCell,
            renderDefinedCell: true,
            disableResizing: true,
            columnStyle: { textAlign: "center" },
            style: { overflow: "visible" },
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }

        if (!!props.keepColumnAsIs) {
          return {
            Header: getColumnNameFromAccessor(key),
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "SAMPLES") {
          return {
            Header: key,
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }

        if (key === "CHROMOSOME") {
          return {
            Header: "Chr",
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            sortType: (rowA, rowB, columnId, desc) => {
              // TODO: sortType needs to return only 1 or -1. Make sure this is the case
              let valA =
                rowA.values[columnId] === "X"
                  ? 23
                  : rowA.values[columnId] === "Y"
                  ? 24
                  : parseInt(rowA.values[columnId]);
              let valB =
                rowB.values[columnId] === "X"
                  ? 23
                  : rowB.values[columnId] === "Y"
                  ? 24
                  : parseInt(rowB.values[columnId]);
              return valA < valB ? -1 : 1;
              // return (valA < valB ? -1 : (valA > valB ? 1 : 0) );
            },
            style: { fontWeight: "bolder", textAlign: "center" },
            // columnStyle: {textAlign: 'center'},
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if(key==="LOC_START") {
          return {
            Header: "Start",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            sortType: sortIntColumn,
            use1000Separator:use1000Separator,
            Cell: (props) => (props.column.use1000Separator ? separateBy1000(props.value) : props.value),
            renderDefinedCell: true,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="LOC_END") {
          return {
            Header: "End",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            sortType: sortIntColumn,
            use1000Separator:use1000Separator,
            Cell: (props) => (props.column.use1000Separator ? separateBy1000(props.value) : props.value),
            renderDefinedCell: true,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if (key === "MANTA_CHROMOSOME_2") {
          return {
            Header: "Chr2 (M)",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            sortType: (rowA, rowB, columnId, desc) => {
              // TODO: sortType needs to return only 1 or -1. Make sure this is the case
              let valA =
                rowA.values[columnId] === "X"
                  ? 23
                  : rowA.values[columnId] === "Y"
                  ? 24
                  : parseInt(rowA.values[columnId]);
              let valB =
                rowB.values[columnId] === "X"
                  ? 23
                  : rowB.values[columnId] === "Y"
                  ? 24
                  : parseInt(rowB.values[columnId]);
              return valA < valB ? -1 : 1;
              // return (valA < valB ? -1 : (valA > valB ? 1 : 0) );
            },
            // style: { fontWeight: "bolder", textAlign: "center" },
            // columnStyle: {textAlign: 'center'},
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "GRIDSS_CHROMOSOME_2") {
          return {
            Header: "Chr2 (G)",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            sortType: (rowA, rowB, columnId, desc) => {
              // TODO: sortType needs to return only 1 or -1. Make sure this is the case
              let valA =
                rowA.values[columnId] === "X"
                  ? 23
                  : rowA.values[columnId] === "Y"
                  ? 24
                  : parseInt(rowA.values[columnId]);
              let valB =
                rowB.values[columnId] === "X"
                  ? 23
                  : rowB.values[columnId] === "Y"
                  ? 24
                  : parseInt(rowB.values[columnId]);
              return valA < valB ? -1 : 1;
              // return (valA < valB ? -1 : (valA > valB ? 1 : 0) );
            },
            // style: { fontWeight: "bolder", textAlign: "center" },
            // columnStyle: {textAlign: 'center'},
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if(key==="CENTROMERE_OVERLAP") {
          return {
            Header: "Cen. Overlap",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 100,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="MAX_COMMON_OVERLAP") {
          return {
            Header: "Common Overlap",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 100,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GRIDSS_MQ") {
          return {
            Header: "Map Qual (G)",
            numeric:true,
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GRIDSS_RP") {
          return {
            Header: "RP (G)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 60,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GRIDSS_ASRP") {
          return {
            Header: "ASRP (G)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 80,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GRIDSS_ASSR") {
          return {
            Header: "ASSR (G)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 80,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GRIDSS_SR") {
          return {
            Header: "SR (G)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 60,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="QUAL_GRIDSS") {
          return {
            Header: "QUAL (G)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 80,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="QUAL_MANTA") {
          return {
            Header: "QUAL (M)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 100,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if (key === "INHERITANCE") {
          return {
            Header: "Inheritance",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "START_IN_REPEAT") {
          return {
            Header: "Starting in Repeat",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "END_IN_REPEAT") {
          return {
            Header: "Ending in Repeat",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if(key==="RECURRENT_OVERLAP_SIZE") {
          return {
            Header: "Recur. Overlap Size",
            id: key,
            accessor: (dt) => dt[key],
            width: 60,
            Cell: VanexSegmentSizeCell,
            sortType: sortIntColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="MAX_RECURRENT_OVERLAP") {
          return {
            Header: "Max Recur. Overlaps",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            sortType: sortFloatColumn,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if (key === "MANTA_POSITION_2") {
          return {
            Header: "Pos 2 (M)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 90,
            use1000Separator:use1000Separator,
            Cell: (props) => (props.column.use1000Separator ? separateBy1000(props.value) : props.value),
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if (key === "GRIDSS_POSITION_2") {
          return {
            Header: "Pos 2 (M)",
            id: key,
            accessor: (dt) => dt[key],
            numeric:true,
            width: 90,
            use1000Separator:use1000Separator,
            Cell: (props) => (props.column.use1000Separator ? separateBy1000(props.value) : props.value),
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GENE_SYMBOLS") {
          return {
            Header: "Genes",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            Cell: VanexMrPhenePhenoTermsCell,
            renderDefinedCell: true,
            sortType: sortColumnByArrayLength,
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if (key === "SEG_SIZE") {
          return {
            Header: "Length",
            id: key,
            accessor: (dt) => dt[key],
            use1000Separator:use1000Separator,
            Cell: VanexSegmentSizeCell,
            width: 90,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "TYPE") {
          return {
            Header: "Type",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "CALLERS") {
          return {
            Header: "CALLERS",
            id: key,
            accessor: (dt) => dt[key],
            width: 120,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "GT_MANTA") {
          return {
            Header: "GT (M)",
            id: key,
            accessor: (dt) => dt[key],
            width: 60,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        if (key === "READS") {
          return {
            Header: "Reads",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }
        // if (key === "FILTER") {
        //   return {
        //     Header: "Filter",
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 50,
        //     setColWidth: function (inWidth) {
        //       this.width = inWidth;
        //     },
        //   };
        // }
        if(key==="OMIM_PHENOTYPES") {
          return {
            Header: "OMIM Phenotypes",
            id: key,
            accessor: (dt) => dt[key],
            width: 200,
            Cell: VanexArrayCell, //VanexMrPhenePhenoTermsCell,
            renderDefinedCell: true,
            sortType: sortColumnByArrayLength,
            // sortType: (rowA, rowB, columnId, desc) => ( !!rowA.values[columnId] && rowA.values[columnId].length > 0 ? (rowA.values[columnId].length < rowB.values[columnId].length ? -1 : 1) : 0),
            // style: {fontWeight: 'bolder',textAlign: 'center'},
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="QUAL"){
          return {
            Header: "Qual",
            id: " QUAL",
            accessor: (dt) => dt[key],
            width: 70,
            useHeated: true,
            // addFilter: true,
            Cell: VanexSVQUALCell,
            style: { textAlign: 'start'},
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if (key.endsWith("_GT")) {
          return {
            // Header: (showPMFPrefix ? `${getSampleLabel(key.slice(0,-3))} [${key}]` : key) ,
            // Header: `${getSampleLabel(key.slice(0,-3))}: ${key}`,
            Header: `${getSampleLabel(
              getSampleIdFromColumnAccessor(key)
            )}: ${key}`,
            id: key,
            accessor: (dt) => dt[key],
            // useGenotypeColor:true,
            withCNVOverlapSchematic:true,
            use_GTinsteadOf_Alt_Percent:true,
            Cell: VanexSVGenotypeCell,
            width: 65,
            style: { textAlign: "start" }, //fontWeight: 'bolder',
            columnStyle: { textAlign: "center" },
            sampleLabel: getSampleLabel(key.slice(0, -3)),
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
          };
        }

        // if (key.endsWith("_QUAL")) {
        //   let tmpHeader = `${getSampleLabel(
        //     getSampleIdFromColumnAccessor(key)
        //   )}: ${key}`;
        //   return {
        //     Header: tmpHeader,
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 50,
            
        //     Cell: (props) => (  Math.round((Number(props.value) + Number.EPSILON) * 100) / 100 ), // Math.trunc(Number(props.value));
        //     setColWidth: function (inWidth) {
        //       this.width = inWidth;
        //     },
        //     useHeated: true,
        //     heatedThresholdValue: 240,
        //   };
        // }

        if (!!getSampleIdFromColumnAccessor(key)) {
          return {
            // Header: key, //key.substring( key.lastIndexOf("_AD_") + 1) + '_' + key.substring(0, key.lastIndexOf("_AD_") ),
            // Header: `${getSampleLabel(key.slice(0,-3))}: ${key}`,
            Header: `${getSampleLabel(
              getSampleIdFromColumnAccessor(key)
            )}: ${key}`,
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            setColWidth: function (inWidth) {
              this.width = inWidth;
            },
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: { textAlign: "center" },
          };
        }

        let colHeader = key;
        let colWidth = 100;

        // ! fix this, this is using RD column-definitions, maybe make it project specific?!
        let colDefinition = getParameterCaseInsensitive(
          variantFormatDefinition["column-definitions"],
          key
        );
        if (!!colDefinition) {
          colHeader = colDefinition["header"] || colHeader;
          colWidth = colDefinition["column_width"] || colWidth;
        }

        // TODO: fix the default withd
        if (colWidth < 30) colWidth = 50;

        return {
          Header: colHeader,
          id: key,
          accessor: (dt) => dt[key],
          width: colWidth,
          // columnStyle: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'} , //instead of this, I added it to the CSS file
          setColWidth: function (inWidth) {
            this.width = inWidth;
          },
        };
      });
    }
    return [];
  });
  // }, [props.dataColumns]);
  const columnData = React.useMemo(() => {
    return(  initialColumnData.map( colInfo => {
      if(colInfo.id==="LOC_START" || colInfo.id==="LOC_END" || colInfo.id==="POSITION_2" || colInfo.id==="SEG_SIZE") return({...colInfo, use1000Separator:use1000Separator})   
      return(colInfo)
    }));
  },[initialColumnData, use1000Separator]);

  const [hiddenColumnsIds, setHiddenColumnsIds] = useState(
    props.hiddenColumnsIds || []
  );
  const [columnOrder, setColumnOrder] = useState(
    Object.values(columnData)
      .map((i) => i.id)
      .filter((t) => !hiddenColumnsIds.includes(t))
  );
  const [sampleColumns, setSampleColumns] = useState({});

  const [PMFSampleColNames, setPMFSampleColNames] = useState(
    props.PMFSampleColNames || []
  );
  // it is better to keep it empty at first // const [PMFSampleColNames, setPMFSampleColNames] = useState(props.PMFSampleColNames || columnData.filter(t => t.id.endsWith("_GT")).map(i => i.id).sort( (elA,elB) => (GetSampleRank(elB, true) - GetSampleRank(elA, true)) ) );
  const [sampleTypeModal, setSampleTypeModal] = useState(null);
  const refForChangeSampleTypeModal = React.useRef();
  const [refresh, setRefresh] = useState(false);
  // const [analysisStartedAt, setAnalysisStartedAt] = useState((!!props.analysisStartedAt ? new Date(props.analysisStartedAt).toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false}) :
  //   new Date().toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false})));
  // const [analysisStartedAt, setAnalysisStartedAt] = useState(props.analysisStartedAt || new Date().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true}));

  function getSampleLabel(sampleID) {
    let label = "";
    if (
      !!sampleID &&
      !!props.currentAnalysisInfo &&
      !!props.currentAnalysisInfo.SAMPLES
    ) {
      // console.log(Object.values(props.currentAnalysisInfo.SAMPLES).find(t => t.SAMPLE_ID === sampleID));
      let sampleInfo = Object.values(props.currentAnalysisInfo.SAMPLES).find(
        (t) => t.SAMPLE_ID === sampleID
      );
      if (!!sampleInfo && !!sampleInfo["SAMPLE_TYPE"])
        label = sampleInfo["SAMPLE_TYPE"];
    }
    return label;
  }

  function getTypeOfColumnsInPMFList(
    columnInfo,
    short = true,
    addUnderline = false,
    inData = PMFSampleColNames
  ) {
    let label = "";
    if (!showPMFPrefix) {
      if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES)
        label = columnInfo.sampleLabel;
    } else {
      switch (inData.indexOf(columnInfo.id)) {
        case 0:
          label = short ? "P" : "Proband";
          break;
        case 1:
          label = short ? "M" : "Mother";
          break;
        case 2:
          label = short ? "F" : "Father";
          break;
        default:
          label = "";
      }
    }
    return addUnderline && label !== "" ? `${label}_` : label;
  }

  function getSampleColumnId(sampleType, sampleColNames) {
    let smpType = Object.keys(sampleColNames).find(
      (el) => sampleColNames[el] === sampleType
    );
    if (!!smpType) return smpType;
    else return "None";
    // return null;
  }

  function getSampleIdFromColumnAccessor(clmnAccessor) {
    let testingSamples = "";
    if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES) {
      props.currentAnalysisInfo.SAMPLES.forEach((element) => {
        if (clmnAccessor.includes(element.SAMPLE_ID))
          testingSamples = element.SAMPLE_ID;
        // testingSamples.push(clmnAccessor.includes(element.SAMPLE_ID)); //SAMPLE_TYPE
      });
    }
    // return(testingSamples.some(i => !!i));
    return testingSamples;
  }

  function getSampleColumnsProbandAtFirst() {
    let columnNamesWOproband = Object.keys(sampleColumns)
      .filter((el) => el !== PMFSampleColNames[0])
      .map((i) => i);
    if (!!PMFSampleColNames[0] && PMFSampleColNames[0] !== "None")
      return [PMFSampleColNames[0], ...columnNamesWOproband];
    else return columnNamesWOproband;
  }

  const [selectedVariantTab, setSelectedVariantTab] = useState(
    props.selectedVariantTab ||
      (props.onlyShowOneTab ? "TAB_ALL" : "TAB_SUMMARY")
  );
  const [tabs, setTabs] = useState(() => {
    let tabsInfo = {
      TAB_SUMMARY: {
        label: "Summary",
        filters: {},
      },
      TAB_SV_INDEL: {
        label: "INS/DEL/DUP", 
        filters: {},
      },
      TAB_SV_BND: {
        label: "BND/TRA", 
        filters: {},
      },
      TAB_SV_INV: {
        label: "INV", 
        filters: {},
      }//,
      // TAB_ALL: {
      //   label: "All SVs",
      //   filters: {},
      // }
    };
    return tabsInfo;
  });

  const [numberOfReviewSaved, setNumberOfReviewSaved] = useState(null);
  const [numberOfNotesSaved, setNumberOfNotesSaved] = useState(null);
  const canSaveNotes =
    props.canSaveNotes ||
    !(!!props.onlyShowOneTab || !props.currentCaseInformation);
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [isAutoSavingNotes, setIsAutoSavingNotes] = useState(
    process.env.NODE_ENV !== "development" || false
  );
  const [autoSaveNotesInterval, setAutoSaveNotesInterval] = useState(3);
  const [autoSaveNotesCounter, setAutoSaveNotesCounter] = useState(0);
  const [savingNotesProgressbarValue, setSavingNotesProgressbarValue] =
    useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [exportProgressbarValue, setExportProgressbarValue] = useState(0);
  const [triggerExportPreparation, setTriggerExportPreparation] =
    useState(false);
  const [preparedDataFromVariantTable, setPreparedDataFromVariantTable] =
    useState(null);
  const [showOtherUsersNotes, setShowOtherUsersNotes] = useState(true);
  const [latestTablePageIndex, setLatestTablePageIndex] = useState(0);
  const [currentVariantId, setCurrentVariantId] = useState(null);

    // const [loadingClinVarPlot, setLoadingClinVarPlot] = useState(false);
    const [autoLoadFirefly, setAutoLoadFirefly] = useState(false);
    // const [clinVarPlotSrc, setClinVarPlotSrc] = useState('');
    // const [errorMessage, setErrorMessage] = useState('');
    const [fireflyGeneID, setFireflyGeneID] = useState(null);


  useEffect(() => {
    setAutoLoadFirefly(false);
    setFireflyGeneID(null);
  }, [currentVariantId]);
  
  useEffect(() => {
    let timer;
    if (numberOfNotesSaved !== null && numberOfNotesSaved !== undefined) {
      timer = setTimeout(() => {
        setNumberOfReviewSaved(null);
        setNumberOfNotesSaved(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [numberOfNotesSaved]);

  useEffect(() => {
    if (!!preparedDataFromVariantTable) {
      navigator.clipboard.writeText(preparedDataFromVariantTable);
      console.log("Copied to Clipboard!");
      // console.log(preparedDataFromVariantTable);
      setPreparedDataFromVariantTable(null);
    }
  }, [preparedDataFromVariantTable]);

  const svChrFromThisGene = React.useMemo(() => {
    if(currentVariantId!==undefined && currentVariantId!==null){
      const currentVariant = props.caseData[currentVariantId];
      // console.log(currentVariant)
      return(
        Object.values(props.caseData).filter(t => ((t.CHROMOSOME === currentVariant.MANTA_CHROMOSOME_2) && ((t.LOC_START === currentVariant.MANTA_POSITION_2) && (t.LOC_START === currentVariant.MANTA_POSITION_2))) || ((t.CHROMOSOME === currentVariant.GRIDSS_CHROMOSOME_2) && (t.LOC_START === currentVariant.GRIDSS_POSITION_2) && (t.LOC_START === currentVariant.GRIDSS_POSITION_2)))
      );
    }
    return([]);
  }, [currentVariantId, props.caseData]);
  
  const otherVariationsNearby = React.useMemo(() => {
    if(currentVariantId!==undefined && currentVariantId!==null){
      const currentVariant = props.caseData[currentVariantId];
      console.log('currentVariant',currentVariant)
      return(
        Object.values(props.caseData).filter((t => (t!==currentVariant)&&(t.CHROMOSOME === currentVariant.CHROMOSOME) && (t.LOC_START >= currentVariant.LOC_START-100) && (t.LOC_START <= currentVariant.LOC_START+100)))          // !! FIX THIS FOR SVs WHICH DONT HAVE A SINGLE GENE
      );
    }
    return([]);
  }, [currentVariantId, props.caseData]);

  //? --------------------------------------------- Filters ----------------------------------

  // const [filterSelectionIndeterminateCheckboxStatus, setFilterSelectionIndeterminateCheckboxStatus] = useState(UNCHECKED);
  const [filterSelectionFormik, setFilterSelectionFormik] = useState(null);
  const [filterSelectionTitle, setFilterSelectionTitle] = useState("Filtering");
  const [filterSelectionModal, setFilterSelectionModal] = useState(null);
  const refForFilterSelectionModal = React.useRef();
  const refForCreatableSelect = React.useRef();

  const [visibleFilterSets, setVisibleFilterSets] = useState(() => {
    let tmpVisibleSetsObj = [];
    Object.values(filterDefinition.SV_FILTER_SET)
      .filter((t) => (t.visible === true) && t.pacbio === usepacbio)
      .forEach((myFilter, filterInd) => {
        // Object.keys(Object.values(filterDefinition.SNV_FILTER_SET).filter(t => t.visible==true)).FILTER_DEFINITIONS).forEach( tabFilterId => {
        // Object.keys(filterDefinition.SNV_FILTER_SET[0].FILTER_DEFINITIONS).forEach( filterId => {
        let tmpFilterSet = {};
        let tmpDefinitionObj = {};

        Object.keys(myFilter.FILTER_DEFINITIONS)
          .filter((t) => myFilter.FILTER_DEFINITIONS[t].visible === true)
          .forEach((filterId) => {
            // * createFilterObjectFromJSON
            // console.log(filterId);
            let filter = myFilter.FILTER_DEFINITIONS[filterId];
            let tmpObj = {
              columnId: filter.column_id,
              label: filter.label,
              value: !!filter.value ? filter.value : null,
              visible:
                filter.visible === true || filter.visible === false
                  ? filter.visible
                  : true,
              editable:
                filter.editable === true || filter.editable === false
                  ? filter.editable
                  : true,
              getFilterDescr: (!!filter.value && !!filter.use_label_as_is === false)
                ? function () {
                    return this.label + this.value;
                  }
                : function () {
                    return this.label;
                  },
              filterFunction: ConstructFilterFunctions(filter),
              isApplied:
                filter.isApplied === true || filter.isApplied === false
                  ? filter.isApplied
                  : false,
              isSpecial: filter.isSpecial === true,
            };
            tmpDefinitionObj[filterId] = tmpObj;
          });
        tmpFilterSet["value"] = filterInd;
        tmpFilterSet["label"] = myFilter.label;
        tmpFilterSet["user_defined"] = false;
        tmpFilterSet["visible"] = myFilter.visible;
        tmpFilterSet["FILTER_DEFINITIONS"] = tmpDefinitionObj;
        tmpVisibleSetsObj.push(tmpFilterSet);
      });

    //TODO: fetch user filters and add to this with tmpFilterSet['user_defined']=true; (async? in another method?)

    // console.log('tmpVisibleSetsObj');
    // console.log(tmpVisibleSetsObj);
    return tmpVisibleSetsObj;
  });

  const [isLoadingVisibleFilterSets, setIsLoadingVisibleFilterSets] =
    useState(false);
  // const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(props.defaultFilterSet || null);
  const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(null);
  const [isFilterSetModified, setIsFilterSetModified] = useState(false);
  const [filterDescriptions, setFilterDescriptions] = useState([]);
  const [globalTabFilters, setGlobalTabFilters] = useState({});

  //? --------------------------------------------- Apply Filters ----------------------------------
  
  // function notIn(inFunction){ return function(){return !inFunction.apply(this, arguments);};}

  // ! ------ Filter ------
  const applyFilter = (tmpVariantData, tabId) => {
    // console.log("applyFilter");
    let filterSpec = [];

    // const IGNORE_FILTERS_4_PLP_CALLS=(!!tabs && tabs["TAB_CLINVAR"] ? tabId==="TAB_CLINVAR" : tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS && tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS.isApplied);
    const IGNORE_FILTERS_4_PLP_CALLS =
      tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS &&
      tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS.isApplied;

    //! THIS MODIFIES THE ORIGINAL DATA AND CANNOT BE UNDONE!!
    if(!!tabs[tabId].filters && !!tabs[tabId].filters.NO_LOC_LINC && !!tabs[tabId].filters.NO_LOC_LINC.isApplied){
      tmpVariantData.forEach( (cnvCall, callIndex) => {
        const allIndexes = tmpVariantData[callIndex].GENE_SYMBOLS.reduce(function(finalList, item, index) {
          if (item.startsWith("LOC") || item.startsWith("LINC")) 
            finalList.push(index);
          return finalList;
        }, []);
        if(!!allIndexes && allIndexes.length >0){
          tmpVariantData[callIndex].GENE_SYMBOLS = tmpVariantData[callIndex].GENE_SYMBOLS.filter( (item, index) => !allIndexes.includes(index));
          tmpVariantData[callIndex].ENTREZ_IDS = tmpVariantData[callIndex].ENTREZ_IDS.filter( (item, index) => !allIndexes.includes(index));
        }
      })
    }

    // ! add this:  check if clinvar then add all variants in those genes to make sure there is no comp.het. !
    Object.values(globalTabFilters)
      .filter((k) => !!k["isApplied"])
      .forEach((filterTerm) => {
        // if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
        tmpVariantData = Object.values(tmpVariantData).filter(
          filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS)
        );
        // }
      });

    if (
      tabId === "TAB_SUMMARY" &&
      !!tabs[tabId] &&
      !!tabs[tabId].filters["MAIN_B"] &&
      !!tabs[tabId].filters["MAIN_B"].isApplied
    ) {
      // console.log(tabs[tabId].filters.MAIN_A.filterFunction);
      // tmpVariantData = Object.values(tmpVariantData).filter( tabs[tabId].filters['MAIN_B'].filterFunction());
      Object.keys(tabs[tabId].filters)
        .filter((k) => !!tabs[tabId].filters[k]["isApplied"])
        .forEach((filterKey) => {
          let filterTerm = tabs[tabId].filters[filterKey];
          if (filterKey !== "MAIN_A") {
            tmpVariantData = Object.values(tmpVariantData).filter(
              filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS)
            );
          }
        });
    } else {
      Object.values(tabs[tabId].filters)
        .filter((k) => !!k["isApplied"])
        .forEach((filterTerm) => {
          tmpVariantData = Object.values(tmpVariantData).filter(
            filterTerm.filterFunction(
              IGNORE_FILTERS_4_PLP_CALLS,
              PMFSampleColNames
            )
          );
        });
      // * making sure that TAB_Filter is applied after other filters
      if (!!tabs[tabId] && !!tabs[tabId].filters["MAIN"]) {
        tmpVariantData = Object.values(tmpVariantData).filter(
          tabs[tabId].filters["MAIN"].filterFunction()
        );
      }
    }
    if (tabId === "TAB_SV_BND") {
      tmpVariantData = Object.values(tmpVariantData).filter(
        (t) => (t['TYPE'].includes( 'BND') | t["TYPE"].includes('TRA'))
      );
      filterSpec.push("TYPE = BND/TRA");
      // filterSpec.push("QUAL > 999");
    }
    if (tabId === "TAB_SV_INDEL") {
      tmpVariantData = Object.values(tmpVariantData).filter(
        (t) => (t['TYPE'].includes( 'INS') | t["TYPE"].includes('DEL')| t["TYPE"].includes('DUP'))
      );
      filterSpec.push("TYPE = INS/DEL/DUP");
      // filterSpec.push("QUAL > 999");
    }
    if (tabId === "TAB_SV_INV") {
      tmpVariantData = Object.values(tmpVariantData).filter(
        (t) => (t["TYPE"].includes('INV'))
      );
      filterSpec.push("TYPE = INV");
      // filterSpec.push("QUAL > 999");
    }
    

    return [tmpVariantData, filterSpec];
  };

  const updateFilterSet = (filterSetIndex, selectedTabId, keepItOff=false) => {
    let tmpTabFilterObj = {};

    // * add Tab-Filters defined in the JSON for this tab
    let tabFilterDefinition = filterDefinition.RD_TAB_FILTER_SET.FILTER_DEFINITIONS[selectedTabId];
    if(tabFilterDefinition !== undefined && tabFilterDefinition !==null){
      Object.keys(tabFilterDefinition).forEach( tabFilterId => {
        // * createFilterObjectFromJSON
        let filter = tabFilterDefinition[tabFilterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: null,
          visible: (filter.visible===true || filter.visible===false) ? filter.visible : false, 
          editable: (filter.editable===true || filter.editable===false) ? filter.editable : false,
          getFilterDescr: function(){ return(this.label)},
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: (filter.isApplied===true || filter.isApplied===false) ? filter.isApplied : true,
          isSpecial: filter.isSpecial===true
        }
        tmpTabFilterObj[tabFilterId]=tmpObj;
      });
    }

    // * add filters from visibleFilterSets
    if (filterSetIndex !==undefined && filterSetIndex !== null && !!visibleFilterSets &&
      !!visibleFilterSets[filterSetIndex] && !!visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS){
      Object.keys(visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS).forEach( filterId => {
        // console.log("selectedTabId",selectedTabId)
        // console.log("filterId",filterId)
        // * if we have a CLINVAR tab, only use IGNORE_FILTERS_4_PLP_CALLS in the CLINVAR tab
        // * and do not apply this in any other tabs, otherwise apply it (use whatever is in JSON).
        if(keepItOff || (!!tabs && tabs["TAB_SV_INDEL"] && selectedTabId!=="TAB_SV_INDEL" && filterId==="MAX_LENGTH"))
          tmpTabFilterObj[filterId]={...visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS[filterId], isApplied:false};
        else
          tmpTabFilterObj[filterId]={...visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS[filterId]};
      });
    }

    setTabs((prevTabs) => ({
      ...prevTabs,
      [selectedTabId]: {
        ...prevTabs[selectedTabId],
        filters: tmpTabFilterObj,
      },
    }));
  };

  //? --------------------------------------------- main useEffects ----------------------------------
  const [mainUseEffectsIsDone, setMainUseEffectsIsDone] = useState(false);

  useEffect(() => {
    // set Modal for selecting column filters
    setFilterSelectionModal(new Modal(refForFilterSelectionModal.current));

    // set Modal for changing sample type
    setSampleTypeModal(new Modal(refForChangeSampleTypeModal.current));

    // ! fix   'MR_PHENE_PHENOTYPE_TERMS' in all three
    let defaultVisibleColumns = []
    if (usepacbio) {
      defaultVisibleColumns = [
        "VANEX_TAGS","VANEX_NOTES","VANEX_REVIEWS","INHERITANCE","CHROMOSOME","GT_MANTA","LOC_START","LOC_END","GENE_SYMBOLS",
        "TYPE","READS","OMIM_PHENOTYPES","MAX_RECURRENT_OVERLAP","SVANN","SEG_SIZE",
      ];
    } else {
      defaultVisibleColumns = [
        "VANEX_TAGS","VANEX_NOTES","VANEX_REVIEWS","INHERITANCE","CHROMOSOME","GT_MANTA","LOC_START","LOC_END","GENE_SYMBOLS",
        "TYPE"," QUAL","READS","OMIM_PHENOTYPES","SEG_SIZE",
      ];
    }
    let include_GT = true;
    let include_QUAL = false;
    let columnToUseForPMF = "_GT";
    let defaultFilterSet = null;

    if (!!props.onlyShowOneTab) defaultVisibleColumns.push("CASE_ID");

    let hiddenCols = [];
    if (!props.hiddenColumnsIds) {
      // * set hidden columns
      // hiddenCols = Object.keys(inputData[0]).filter(t => !defaultVisibleColumns.includes(t) ).map( k => k);
      //! fix this, move columnData to here?! and then use the top line instead of the below

      Object.values(columnData).forEach((column) => {
        if (
          !defaultVisibleColumns.includes(column.id) &&
          !(include_GT && column.id.endsWith("_GT")) 
        ) {
          hiddenCols.push(column.id);
        }
      });
      setHiddenColumnsIds(hiddenCols);
    } else {
      hiddenCols = hiddenColumnsIds;
    }

    Object.keys(tabs).forEach((tabId) => {
      let filterSetIndex = undefined;
      if (tabId !== "TAB_SUMMARY") filterSetIndex = defaultFilterSet || 0;
      updateFilterSet(filterSetIndex, tabId, tabId === "TAB_PARADIGM");
    });

    // setSelectedFilterSetIndex(defaultFilterSet);

    if (selectedVariantTab === "TAB_SUMMARY") setSelectedFilterSetIndex(null);

    let sampleColNames = {};
    if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES) {
      props.currentAnalysisInfo.SAMPLES.forEach((element) => {
        sampleColNames[`${element.SAMPLE_ID}${columnToUseForPMF}`] =
          element.SAMPLE_TYPE;
        // sampleColNames[element.SAMPLE_TYPE]=`${element.SAMPLE_ID}${columnToUseForPMF}`;
      });
      if (!props.PMFSampleColNames)
        setPMFSampleColNames([
          getSampleColumnId("Proband", sampleColNames),
          getSampleColumnId("Mother", sampleColNames),
          getSampleColumnId("Father", sampleColNames),
        ]);
    } else {
      columnData
        .filter((t) => t.id.endsWith(columnToUseForPMF))
        .map((i) => i.id)
        .sort((elA, elB) => GetSampleRank(elB, true) - GetSampleRank(elA, true))
        .forEach((element) => {
          sampleColNames[element] = "None";
        });
    }
    setSampleColumns(sampleColNames);

    // * set column order
    // if (!!props.PMFSampleColNames) sampleColNames= PMFSampleColNames;
    // if(sampleColNames.length > 0){
    //   let colOrder = Object.values(columnData).map(i => i.id).filter(t => (!hiddenCols.includes(t) && !PMFSampleColNames.includes(t) ) );
    //   console.log(colOrder);
    //   console.log(PMFSampleColNames);
    //   colOrder =[...colOrder, ...PMFSampleColNames]
    //   console.log(colOrder);
    //   setColumnOrder( colOrder );
    // }
    setColumnOrder(
      Object.values(columnData)
        .map((i) => i.id)
        .filter((t) => !hiddenCols.includes(t))
    );
    // setColumnOrder( Object.values(columnData).map(i => i.id).filter(t => !hiddenCols.includes(t)).sort( (elA,elB) => (defaultVisibleColumns.indexOf(elA) - defaultVisibleColumns.indexOf(elB))  ) );

    // ! does this work? fix it if not!
    if (!props.userName && canSaveNotes) {
      toast.error(
        `Cannot save notes since you are either not logged in or your session has expired! (${props.userName})`,
        { duration: 8000 }
      );
    }
    setMainUseEffectsIsDone(true);
  }, []);

  const variantData = React.useMemo(() => {
    let [filteredVariantData, filterSpec] = applyFilter(
      props.caseData,
      selectedVariantTab
    ); // ! [...props.caseData] or  props.caseData ?
    setFilterDescriptions(filterSpec);
    return filteredVariantData;
  }, [tabs, globalTabFilters, selectedVariantTab, props.caseData, refresh]); //! deconstruct props and use individual ones??

  useEffect(() => {
    setLatestTablePageIndex(0);
  }, [tabs, globalTabFilters, selectedVariantTab]);

  //? --------------------------------------------- saveNotes ----------------------------------

  const saveNotes = () => {
    // ! find a better way to update progress bar?
    const updateSavingNotesProgress = (value, final = false) => {
      setSavingNotesProgressbarValue(value);
      // const interval = setInterval(() => {  // ! fix this!
      //   setSavingNotesProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsSavingNotes(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    };

    // ! does this work? fix it if not!
    if (!(!!props.userName && !!props.currentCaseInformation.CASE_ID)) {
      toast.error(
        `Cannot save notes since you are either not logged in or your session has expired 🙁 (${props.userName} - ${props.currentCaseInformation.CASE_ID})`
      );
      return 1;
    }

    setIsSavingNotes(true);

    updateSavingNotesProgress(10);

    const recordsWithReview = Object.values(props.caseData)
      .filter((t) => t.VANEX_REVIEWS["REVIEW_VALUE"] > 0)
      .map((variant) =>
        (({ VANEX_REVIEWS,VANEX_TAGS,VANEX_NOTES, ROW_INDEX, ...rest }) => { // items can be added here and then not returned to exclude from review
          let tmpDate = !!VANEX_REVIEWS["UPDATED_AT"]
            ? VANEX_REVIEWS["UPDATED_AT"]
            : new Date();
          return {
            REVIEW_VALUE: VANEX_REVIEWS["REVIEW_VALUE"] || -1,
            ...rest,
            UPDATED_AT: tmpDate,
          };
        })(variant)
      );

    if (!!recordsWithReview && recordsWithReview.length > 0) {
      // console.log(recordsWithReview);
      let caseId = props.currentCaseInformation.CASE_ID; // *no need for .toUpperCase();
      let userName = "REVIEWS";
      let fileName = `${caseId}_${props.currentAnalysisInfo.ANALYSIS_ID}_${userName}.json.gz`;

      // const resultAsBinString = pako.gzip(Object.values(recordsWithReview).map(x=>JSON.stringify(x)).join(''));
      const resultAsBinString = pako.gzip(JSON.stringify(recordsWithReview));

      // const stringUtf16ThatWeNeedInUtf8 = JSON.stringify(recordsWithReview);
      // const stringUtf8 = unescape(encodeURIComponent(stringUtf16ThatWeNeedInUtf8));
      // const resultAsBinString = pako.gzip(stringUtf8);
      // const gzippedBlob = new Blob([resultAsBinString]);

      GetSignedURL(
        "nch-igm-vanex",
        `sv_case_notes/CASE_ID=${caseId}/ANALYSIS_ID=${props.currentAnalysisInfo.ANALYSIS_ID}/USERNAME=${userName}/${fileName}&upload=true`
      )
        .then((result_json) => {
          updateSavingNotesProgress(25);
          return fetch(result_json, {
            method: "PUT",
            // headers: {
            //   'Content-Type': 'application/json'
            //   'Content-Encoding': 'gzip',
            // },
            body: resultAsBinString, //Object.values(recordsWithReview).map(x=>JSON.stringify(x)).join('\n')
          });
        })
        .then((response) => {
          if (response.status === 200) {
            setNumberOfReviewSaved(Object.keys(recordsWithReview).length);
            // toast.success(`${Object.keys(recordsWithReview).length} Reviews Saved!`, {duration:2000});
            // setShouldBlockNavigation(false);
            // setIsSavingNotes(false);
            // setAutoSaveNotesCounter(0);
          } else {
            throw Error(`${response.status} - ${response.statusText}`);
          }
        })
        .catch((e) => {
          toast.error(`Failed to save review data. (${e})`);
          // setShouldBlockNavigation(false);
          // setIsSavingNotes(false);
        });
    }

    const userNotes = Object.values(props.caseData)
      .filter((t) => !!t.VANEX_TAGS || !!t.VANEX_NOTES["CURRENT_USER_NOTE"])
      .map((variant) =>
        (({ VANEX_TAGS, VANEX_NOTES, CHROMOSOME, LOC_START, LOC_END}) => {
          let tmpDate = !!VANEX_NOTES["UPDATED_AT"]
            ? VANEX_NOTES["UPDATED_AT"]
            : new Date();
          return {
            VANEX_TAGS,
            VANEX_NOTES: VANEX_NOTES["CURRENT_USER_NOTE"] || "",
            CHROMOSOME,
            LOC_START,
            LOC_END,
            UPDATED_AT: tmpDate,
          };
        })(variant)
      );
    // .map( variant => ( ({ VANEX_TAGS, VANEX_NOTES, CHROMOSOME, POSITION, REF, ALT }) => ({ VANEX_TAGS, VANEX_NOTES:VANEX_NOTES['CURRENT_USER_NOTE'], CHROMOSOME, POSITION, REF, ALT, UPDATED_AT:VANEX_NOTES['UPDATED_AT'] }))(variant));

    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ FILTER, CHROMOSOME, POSITION, REF, ALT }) => ({ FILTER, CHROMOSOME, POSITION, REF, ALT }))(variant));
    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ FILTER, CHROMOSOME, POSITION, REF, ALT }) => ({ FILTER, CHROMOSOME, POSITION, REF, ALT, VARIANTID: `${CHROMOSOME}_${POSITION}_${REF}_${ALT}` }))(variant));
    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ CHROMOSOME, POSITION, REF, ALT, FILTER, index }) => ({ CHROMOSOME, POSITION, REF, ALT, FILTER, index }))(variant));

    // * no need to check for this
    // if(!(!!userNotes && userNotes.length > 0)) {
    //   toast.error('No Notes to Save!');
    //   setShouldBlockNavigation(false);
    //   setIsSavingNotes(false);
    //   return;
    // }

    let caseId = props.currentCaseInformation.CASE_ID; // *no need for .toUpperCase();
    let userName = props.userName.replaceAll(" ", "_"); // ! do we need to make it .toUpperCase()?
    let fileName = `${userName}.json`;
    //!! add this back let fileName= `${caseId}_${props.currentAnalysisInfo.ANALYSIS_ID}_${userName}.json`;
    // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?upload=true&bucket=nch-igm-vanex&key=case_notes/CASE_ID=${caseId}/${userName}.json`;
    // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?upload=true&bucket=nch-igm-vanex&key=case_notes/CASE_ID=${caseId}/USERNAME=${userName}/${userName}.json`;
    GetSignedURL(
      "nch-igm-vanex",
      `sv_case_notes/CASE_ID=${caseId}/ANALYSIS_ID=${props.currentAnalysisInfo.ANALYSIS_ID}/USERNAME=${userName}/${fileName}&upload=true`
    )
      .then((result_json) => {
        updateSavingNotesProgress(50);
        return fetch(result_json, {
          method: "PUT",
          headers: {
            // 'Accept': 'application/json',
            "Content-Type": "application/json",
          },
          body: Object.values(userNotes)
            .map((x) => JSON.stringify(x))
            .join("\n"),
          // body: JSON.stringify(userNotes)
          // body: JSON.stringify({a: 1, b: 'Textual content'})
        });
      })
      .then((response) => {
        if (response.status === 200) {
          setNumberOfNotesSaved(Object.keys(userNotes).length);
          // setTimeout(() => {setSaveNotesMessage(`${Object.keys(userNotes).length} Notes Saved!`)}, 1000); //! fix this hack!
          // toast.success(`${Object.keys(userNotes).length} Notes Saved!`, {duration:2000});
          setShouldBlockNavigation(false);
          setIsSavingNotes(false);
          setAutoSaveNotesCounter(0);
        } else {
          throw Error(`${response.status} - ${response.statusText}`);
        }
      })
      .catch((e) => {
        toast.error(`Failed to save notes. (${e})`);
        setShouldBlockNavigation(false);
        setIsSavingNotes(false);
      });
  };

  //? --------------------------------------------- Exports ----------------------------------

  const shouldExportThisTab = (scope, tabId) => {
    switch (scope) {
      case "summary":
        return tabId === "TAB_SUMMARY";
      case "noOriginalData":
        return tabId !== "TAB_ALL";
      default:
        return true;
    }
  };

  const notesOptions = (scope) => {
    if (scope === "autoSavingNotes")
      setIsAutoSavingNotes((prevState) => !prevState);
    if (scope === "hideOtherUsersNotes")
      setShowOtherUsersNotes((prevState) => !prevState);
  };

  const exportSnvAsXlsx = (scope) => {
    if (scope === "clipboard") {
      setTriggerExportPreparation(true);
      // navigator.clipboard.writeText(preparedDataFromVariantTable);

      // var header = visibleColumns.filter(col => col.id!=="VANEX_TAGS").map(k => k.Header).join('\t')+"\n";
      const visColumns = columnData.filter(
        (col) => col.id !== "VANEX_TAGS" && !hiddenColumnsIds.includes(col.id)
      );
      const header =
        visColumns
          .map((k) => (k.id === "VANEX_NOTES" ? k.Header + "\t." : k.Header))
          .join("\t") + "\n";

      var rowData = variantData
        .map((variantCallInfo) =>
          visColumns
            .map((column) => column.id)
            .map((colId) => {
              let cellValue = variantCallInfo[colId];
              if (cellValue) {
                if (colId === "VANEX_NOTES") {
                  // console.log(variantCallInfo[colId]);
                  return (
                    variantCallInfo[colId].CURRENT_USER_NOTE.replace("\n", "") +
                    "\t [" +
                    variantCallInfo[colId].ALL_USERS_NOTES.filter(
                      (nt) => nt.IS_SET_BY_OTHERS === true
                    )
                      .map(
                        (x) =>
                          " «" +
                          x.USERNAME +
                          "»: " +
                          x.VANEX_NOTES.replace("\n", "")
                      )
                      .join("],  [") +
                    "]"
                  );
                } else if (colId === "VANEX_REVIEWS") {
                  return variantCallInfo[colId].REVIEW_VALUE;
                } else if (
                  typeof cellValue === "string" &&
                  cellValue.trim() !== ""
                ) {
                  // if(colId=="LINK_JIRA"){
                  //   return("=HYPERLINK('"+cellValue+"';'JIRA')")
                  // }
                  // if(colId=="LINK_CONFLUENCE"){
                  //   return("=HYPERLINK('"+cellValue+"';'JIRA')")
                  // }
                  cellValue = cellValue
                    .replaceAll("\t", " ")
                    .replaceAll("\n", " ")
                    .trim();
                }
              }
              return cellValue;
            })
            .join("\t")
        )
        .join("\n");
      // console.log(header + rowData)
      navigator.clipboard.writeText(header + rowData);

      return;
    }

    // ! find a better way to update progress bar?
    const updateExportProgress = (value, final = false) => {
      setExportProgressbarValue(value);
      // setTimeout(() => {setExportProgressbarValue(value)}, 100); //! fix this hack!
      // const interval = setInterval(() => { // ! fix this!
      //   setExportProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsExporting(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    };

    setIsExporting(true);

    let outputFileName = "VaNex-output";
    let xlsxSheetData = [];

    updateExportProgress(1);
    // let deepCopiedCaseData = JSON.parse(JSON.stringify(props.caseData));
    let deepCopiedCaseData = structuredClone(props.caseData);

    updateExportProgress(10);

    let totalTabs = Object.keys(tabs).length;
    Object.keys(tabs).forEach((tabId, index) => {
      if (shouldExportThisTab(scope, tabId)) {
        // * apply filteres for each tab!
        let [tmpVariantData, filterSpec] = applyFilter(
          deepCopiedCaseData,
          tabId
        ); //! [...props.caseData], tabId);  // ! [...props.caseData] or  props.caseData ?
        Object.keys(tmpVariantData).forEach((rId) => {
          tmpVariantData[rId].VANEX_NOTES =
            tmpVariantData[rId].VANEX_NOTES["CURRENT_USER_NOTE"];
        });

        // * add a sheet for each tab!
        xlsxSheetData.push([
          tabs[tabId].label,
          XLSX.utils.json_to_sheet(tmpVariantData, {
            header: ["VANEX_TAGS", "VANEX_NOTES", "CHROMOSOME"],
          }),
        ]);
        // xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['CHROMOSOME' POSITION	STOP_POSITION	REF	ALT	SAMPLES	INHERIT_MODEL	QUAL	FILTER	GENE	ENTREZ_ID	LOC_IN_GENE	DIST_TO_SPLICE_SITE	DIST_TO_CODING_REGION	EFFECT	IMPACT	MANE_HGVS	HGVS	HGVS_ALL_PRIORITIZED	INFORMATION, 	ROW_INDEX	VANEX_TAGS	VANEX_NOTES']})]);
      }
      updateExportProgress(10 + (index + 1) * (60 / totalTabs));
    });

    updateExportProgress(70);

    //! add this? xlsxSheetData.push(['Filtered (raw)' , XLSX.utils.json_to_sheet(props.caseData)]);
    //! add this? xlsxSheetData.push(['Filtered (Global)' , XLSX.utils.json_to_sheet(globalFilteredVariantData)]);
    //! add this? xlsxSheetData.push(['Selected' , XLSX.utils.json_to_sheet(variantData)]);

    const wb = XLSX.utils.book_new();
    xlsxSheetData.forEach((element) => {
      // console.log('appending: ', element);
      XLSX.utils.book_append_sheet(wb, element[1], element[0]);
    });

    updateExportProgress(80);

    if (!!props.currentCaseInformation) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet([props.currentCaseInformation]),
        "Case Info"
      );
      if (!!props.currentCaseInformation.CASE_ID) {
        outputFileName = props.currentCaseInformation.CASE_ID;
      }
    }
    if (!!props.currentAnalysisInfo) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet([props.currentAnalysisInfo]),
        "Analysis Info"
      );
    }

    updateExportProgress(90);
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, outputFileName + ".xlsx");
    toast.success("Export Completed!");
    setIsExporting(false); // updateExportProgress(99, true);
  };

  //? --------------------------------------------- process values ----------------------------------


  const processOMIMArrayTerms = (omimTermsString) => {
    // let omimTerms = omimTermsString.split(';');
    return(
      omimTermsString.map((term, index) => {
        return(
          <div  key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center" 
            dangerouslySetInnerHTML={{ __html: term.replaceAll(/,? ?autosomal dominant/ig, '<span class="ms-1 badge bg-orange">AD</span>')
              .replaceAll(/,? ?autosomal recessive/ig, '<span class="ms-1 badge bg-green">AR</span>')
              .replaceAll(/,? ?X-linked dominant/ig, '<span class="ms-1 badge bg-orange">XLD</span>')
              .replaceAll(/,? ?X-linked recessive/ig, '<span class="ms-1 badge bg-green">XLR</span>') }} />
        );
      })
    );
  }

  //? --------------------------------------------- renders ----------------------------------

  const renderVariantInfo = () => {
    if (currentVariantId === null || !props.caseData[currentVariantId]) {
      return (
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            <div className="card m-2 shadow-sm flex-grow-1">
              <h5 className="card-header bg-light-blue">Variant</h5>
              <div className="card-body d-flex flex-row flex-nowrap align-items-center justify-content-center">
                <h3>No variant selected</h3>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const variant = props.caseData[currentVariantId];

      // console.log({name: variant.GENE, entrez_id:variant.ENTREZ_ID});
      // console.log([{POSITION:parseInt(variant.POSITION), PRT_POS:getPrtPos(variant.MANE_HGVS)}]);
      //console.log('variant',variant)
      return (
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            
            
          {console.log(variant.CALLERS[0])}
          <div className="card m-1 shadow-sm flex-grow-1">
             
              {variant.CALLERS[0] === "PACBIO" ? (
                <h5 className="variant-card-header bg-light-blue d-flex flex-row">Position: <div className="mx-2 user-select-all variant-full-name-display">
                    {((variant.TYPE === "BND") && (!!variant.MATEID))
                      ? variant.MATEID.replace(/^pbsv\.BND\./, "")
                      : variant.CHROMOSOME + ":" + variant.LOC_START+ "-" + variant.LOC_END}
                  </div> 
                  <span className="ms-4">Type: <span className="mx-1 user-select-all">
                    {((variant.TYPE === "BND") && (!!variant.MATEDIST))
                      ? "BND (same CHR)"
                      : (variant.TYPE === "BND") ? "BND (not same Chr) "
                      : variant.TYPE}
                  </span></span>
                  <span className="ms-4">Length: <span className="mx-1 user-select-all">
                    {((variant.TYPE === "BND") && (!!variant.MATEDIST))
                      ? variant.MATEDIST
                      : variant.SEG_SIZE}
                  </span></span>
                  <div className="mx-4 user-select-all variant-full-name-display">
                    <span className="mx-2">HomSeq: <span className="mx-1 user-select-all">
                      {variant.ALT}
                    </span></span>
                    <span className="mx-2">Seq: <span className="mx-1 user-select-all">
                      {variant.REF}
                    </span></span>
                  </div>
                </h5>
              ) : (
                <h5 className="variant-card-header bg-light-blue d-flex flex-row">Position: <div className="mx-2 user-select-all variant-full-name-display">
                    {variant.CHROMOSOME}:{((variant.TYPE === "BND") && (variant.CHROMOSOME === variant.MANTA_CHROMOSOME_2))
                      ? Math.min(variant.LOC_START, variant.MANTA_POSITION_2)
                      : variant.LOC_START}-{((variant.TYPE === "BND") && (variant.CHROMOSOME === variant.MANTA_CHROMOSOME_2))
                      ? Math.max(variant.LOC_START, variant.MANTA_POSITION_2)
                      : (variant.TYPE === "TRA") ? variant.GRIDSS_POSITION_2 : variant.LOC_END}
                  </div> 
                  <span className="ms-4">Type: <span className="mx-1 user-select-all">
                    {((variant.TYPE === "BND") && (variant.CHROMOSOME === variant.MANTA_CHROMOSOME_2))
                      ? "BND (same CHR)"
                      : (variant.TYPE === "BND") ? "BND (not same Chr) " + variant.MANTA_CHROMOSOME_2 + ":" + variant.MANTA_POSITION_2
                      : variant.TYPE}
                  </span></span>
                  <span className="ms-4">Length: <span className="mx-1 user-select-all">
                    {((variant.TYPE === "BND") && (variant.CHROMOSOME === variant.MANTA_CHROMOSOME_2))
                      ? Math.abs(variant.MANTA_POSITION_2 - variant.LOC_START)
                      : (variant.TYPE === "TRA") ? Math.abs(variant.GRIDSS_POSITION_2 - variant.LOC_START)
                      : variant.SEG_SIZE}
                  </span></span>
                  <div className="mx-4 user-select-all variant-full-name-display">
                    <span className="mx-2">HomSeq: <span className="mx-1 user-select-all">
                      {variant.CALLERS === "MANTA" ? variant.MANTA_HOMSEQ : "-"}
                    </span></span>
                    <span className="mx-2">Seq: <span className="mx-1 user-select-all">
                      {variant.CALLERS === "MANTA" ? variant.MANTA_SEQUENCE
                      : variant.CALLERS === "GRIDSS" ? variant.GRIDSS_SEQUENCE : "-"}
                    </span></span>
                  </div>
                </h5>
              )}

              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-0" style={{minWidth:'45rem'}}>
                  
                
                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                  <a href={'https://varsome.com/variant/hg38/'+variant.CHROMOSOME+'-'+variant.LOC_START+'?annotation-mode=germline'}  target="_blank" rel="noreferrer"><i className="fas fa-check"></i> Varsome</a>                 
                  </div>

                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                  <a href={'https://www.ncbi.nlm.nih.gov/dbvar/?term='+variant.CHROMOSOME+'%5BChr%5D+AND+('+variant.LOC_START+'%5BChrPos%5D+OR+'+variant.LOC_END+'%5BChrEnd%5D)'} target="_blank" rel="noreferrer"><i className="fas fa-dna"></i> dbVAR</a>
                  </div>

                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    <a href={'http://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&position=chr'+variant.CHROMOSOME+':'+(((variant.TYPE === "BND") && (variant.CHROMOSOME === variant.MANTA_CHROMOSOME_2))? Math.min(variant.LOC_START,variant.MANTA_POSITION_2) :variant.LOC_START)+'-'+(((variant.TYPE === "BND") && (variant.CHROMOSOME === variant.MANTA_CHROMOSOME_2))? Math.max(variant.LOC_START,variant.MANTA_POSITION_2) : (variant.TYPE === "TRA")? variant.GRIDSS_POSITION_2 : variant.LOC_END)} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> UCSC</a>
                  </div>
                  
                  <div className="text-start flex-grow-1">
                  <a href={'https://www.deciphergenomics.org/search/patients/results?q='+variant.CHROMOSOME+'%3A'+variant.LOC_START+'-'+variant.LOC_END+''} target="_blank" rel="noreferrer">
                    <i className="fas fa-external-link-alt"></i> DECIPHER</a>                    
                </div>
                  
                </div>
               </div>
              {/* <footer className="card-footer text-muted">2 days ago <cite title="Source Title">Source Title</cite></footer> */}
            </div>
          
            {/* {renderClinVar()} */}
            <VariantInfoBox title="Firefly" min_width="10rem" >
              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'10rem'}}>
                {(!!variant.GENE_SYMBOLS && variant.GENE_SYMBOLS.length > 0)
                ?
                  <>
                    <div className="rounded-pill px-2 mb-1 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{variant.GENE_SYMBOLS.length}</div>
                    {variant.GENE_SYMBOLS.map( (geneSymbol, index) => <div className="firefly-gene-name-in-cnv rounded-pill px-2 mx-1 mb-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center"
                    key={index} onClick={e => {if (!!variant.ENTREZ_IDS && !!variant.ENTREZ_IDS[index]){ setFireflyGeneID(variant.ENTREZ_IDS[index]); setAutoLoadFirefly(true); } }}>{geneSymbol}</div>)}
                  </>
                :
                  '-'
                }
              </div>
              <div  className="variant-card-body d-flex flex-column align-items-center justify-content-center">
                {(autoLoadFirefly)
                  ?
                    <Firefly 
                      className="mb-3"
                      entrezId={fireflyGeneID}
                      useStagingEnv = {process.env.REACT_APP_ENV!=='prod'}
                    />
                  : null }
              </div>
            </VariantInfoBox>
            
            {/* {renderOMIMPhenotypes()} */}
            <VariantInfoBox title="OMIM Phenotypes" min_width="10rem" >
              {( (!!variant.OMIM_PHENOTYPES && variant.OMIM_PHENOTYPES.length > 0)
                ?
                <>
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item text-center m-1 d-flex flex-row">
                      <div className="flex-grow-1 d-flex flex-row flex-wrap">
                        {(variant.OMIM_PHENOTYPES===undefined) ? "Error!" : ( (variant.OMIM_PHENOTYPES===null) ? "-" : processOMIMArrayTerms(variant.OMIM_PHENOTYPES) )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row w-100"><OmimInfo omimPhenotypes={variant.OMIM_PHENOTYPES.join(";")}  /></div>
                </>
                :
                <div className="d-flex flex-row flex-nowrap ">
                  <div className="variant-item text-center m-1 d-flex flex-row">
                    {'-'}
                  </div>
                </div>
              )}
            </VariantInfoBox>
            {/* {renderOMIMGenes()} */}
            <VariantInfoBox title="OMIM Genes" min_width="10rem" >
              <div className="variant-card-body p-0 m-1 d-flex flex-row show-overflow bg-white" style={{ minHeight:'1rem', maxHeight:'10rem'}}>
                {(!!variant.OMIM_INFO && variant.OMIM_INFO.length > 0)
                ?
                  <>
                    <span className="rounded-pill px-2 mb-1 border-light-blue bg-blue text-light d-flex flex-row flex-nowrap align-items-center justify-content-center" >{variant.OMIM_INFO.length}</span>
                    {variant.OMIM_INFO.map( (omimInfo, index) => <span key={index} className="rounded-pill px-2 mx-1 mb-1 border-light-blue d-flex flex-row flex-nowrap text-nowrap align-items-center justify-content-center">
                      {omimInfo.MIM_IDS.map( (mimID, mimIndex) => <a key={mimIndex} href={'https://www.omim.org/entry/'+mimID} target="_blank" rel="noreferrer">{omimInfo.GENE}{!!omimInfo.TYPES && omimInfo.TYPES.join(";")!=="gene" ? ` (${omimInfo.TYPES})` : null}</a>)}
                      {/* <a href={'https://www.omim.org/entry/'+omimInfo.MIM_IDS} target="_blank" rel="noreferrer">{omimInfo.GENE}{omimInfo.TYPES!=="gene" ? ` (${omimInfo.TYPES})` : null}</a> */}
                      {/* <div className="d-flex flex-row w-100"><OmimInfo omimPhenotypes={null} omimNumber={mimNumber} mimNumbers={[mimNumber]}/></div> */}
                    </span>)}
                  </>
                :
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item text-center m-1 d-flex flex-row">
                      {'-'}
                    </div>
                  </div>
                }
              </div>
            </VariantInfoBox>

            {/* {renderdOtherVriationInthisGene()} */}
            <VariantInfoBox title={<span>Variants Near This Position (+-100bp)<span className="fs-6 ms-3">({Object.keys(otherVariationsNearby).length})</span></span>}
                min_width="10em" collapsed>
                <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                  <VariantTable
                    initialSortBy={props.initialSortBy}
                    updateVariantTableData={(variantIndex, columnId, value) => { 
                      if(!!props.updateVariantTableData){ 
                        setShouldBlockNavigation(true);
                        props.updateVariantTableData(variantIndex, columnId, value);
                      } else {toast.error('Cannot update this value!')}
                      if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                        setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                      }
                    }}
                    // displayVariantInfo={(variantId) => setCurrentVariantId(variantId)}
                    getTabName={() => selectedVariantTab}
                    hiddenColumnsIds={hiddenColumnsIds}
                    // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                    PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                    columns={columnData} data={otherVariationsNearby}
                  />
                </div>
              </VariantInfoBox>

            {/* {renderdOtherVriationInthisGene()} */}
            {((variant.TYPE !== "INS") && (variant.TYPE !== "DEL") && (variant.TYPE !== "DUP") && (variant.TYPE !== "INV")) ?
              <VariantInfoBox title={<span>Variants at Second Position <span className="fs-6 ms-3">({Object.keys(svChrFromThisGene).length})</span></span>}
                min_width="10em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">All SNVs in {variant.GENE} {' ('}{Object.keys(otherVariationsInThisGene).length}{')'}</h5> */}
                <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                  <VariantTable
                    initialSortBy={props.initialSortBy}
                    updateVariantTableData={(variantIndex, columnId, value) => { 
                      if(!!props.updateVariantTableData){ 
                        setShouldBlockNavigation(true);
                        props.updateVariantTableData(variantIndex, columnId, value);
                      } else {toast.error('Cannot update this value!')}
                      if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                        setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                      }
                    }}
                    // displayVariantInfo={(variantId) => setCurrentVariantId(variantId)}
                    getTabName={() => selectedVariantTab}
                    hiddenColumnsIds={hiddenColumnsIds}
                    // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                    PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                    columns={columnData} data={svChrFromThisGene}
                  />
                </div>
              </VariantInfoBox>
              : <></>
            }{/* </div> */}
            

            {/* {renderIGV()} */}
            <div className="card m-1 p-0 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              {/* <h5 className="variant-card-header bg-light-blue">IGV</h5> */}
              {/* <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center"> */}
              <div className="variant-card-body d-flex flex-column m-1 mt-0 p-0">
                <IgvVaNex chromosome={variant.CHROMOSOME} position={variant.LOC_START} currentAnalysisInfo={props.currentAnalysisInfo}/>
              </div>
            </div>
            
            
            <VariantInfoBox title="Complete Record" min_width="10em" collapsed>
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Complete Record</h5> */}
              <div className="card-body">
                {/* {Object.keys(variant).map( (element) => { return( String(variant[element])) } ) } */}
                { Object.keys(variant).map( (element, i) => <span className="" key={i}> {element}= { String(variant[element]) } <br/></span>) }
              </div>
            </VariantInfoBox>{/* </div> */}
          </div>
        </div>
      );
    }
  };

  const onVariantFilterChange = (event, useGlobalTabFilters) => {
    // console.log(event.target.name , ' is ', event.target.value);
    let currentFilterName = event.target.name;

    if (event.target.type === "checkbox") {
      if (useGlobalTabFilters) {
        setGlobalTabFilters((prevGlobalTabFilters) => ({
          ...prevGlobalTabFilters,
          [currentFilterName]: {
            ...prevGlobalTabFilters[currentFilterName],
            isApplied: Boolean(event.target.checked),
          },
        }));
      } else {
        // * update isApplied of this filter in the current tab
        setTabs((prevTabs) => ({
          ...prevTabs,
          [selectedVariantTab]: {
            ...prevTabs[selectedVariantTab],
            filters: {
              ...prevTabs[selectedVariantTab].filters,
              [currentFilterName]: {
                ...prevTabs[selectedVariantTab].filters[currentFilterName],
                isApplied: Boolean(event.target.checked),
              },
            },
          },
        }));
      }
    }
  };

  const renderFilterInfo = (filterList, useGlobalTabFilters) => {
    if (Object.keys(filterList).length === 0) {
      return <label className="list-group-item ps-4">Found None.</label>;
    } else {
      return (
        <React.Fragment>
          {Object.keys(filterList)
            .filter((t) => filterList[t].editable === true)
            .map((filterTerm, index) => {
              return (
                <label className="list-group-item pe-2" key={index}>
                  <div className="form-check form-switch m-0">
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      name={filterTerm}
                      onChange={(e) =>
                        onVariantFilterChange(e, useGlobalTabFilters)
                      }
                      checked={filterList[filterTerm]["isApplied"]}
                    />
                    <div className="d-flex flex-row">
                      <div className="filter-description flex-grow-1">
                        {filterList[filterTerm].getFilterDescr()}
                      </div>
                      {useGlobalTabFilters ? (
                        <button
                          className="btn move-filter-button flex-center"
                          onClick={() => {
                            addFilterToSet(filterList[filterTerm].columnId);
                          }}
                        >
                          <span className="plus-icon-next-to-arrow">
                            <i className="fas fa-plus fa-xs"></i>
                          </span>
                          <i className="fas fa-level-up-alt"></i>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </label>
              );
            })}
        </React.Fragment>
      );
    }
  };

  // }

  const createNewFilterSet = (inputValue) => {
    // * check to make sure there is no other filter-set with the same name (including the invisible ones)
    let filteKey = inputValue.toUpperCase().replace(/\W/g, "");
    console.log(
      filterDefinition.XLS_FILTER_SET.filter(
        (t) => t.label.toUpperCase().replace(/\W/g, "") === filteKey
      ).length > 0
    );
    if (
      filterDefinition.XLS_FILTER_SET.filter(
        (t) => t.label.toUpperCase().replace(/\W/g, "") === filteKey
      ).length > 0
    ) {
      toast.error(
        "The filter-set name needs to be unique. A hidden filter-set might exist!",
        { duration: 6000 }
      );
      return;
    }

    setIsLoadingVisibleFilterSets(true);
    const newFilterSet = {
      value: visibleFilterSets.length,
      label: inputValue,
      user_defined: true,
      visible: true,
      FILTER_DEFINITIONS: {},
    };
    setVisibleFilterSets([...visibleFilterSets, newFilterSet]);

    // * show the new FilterSet
    refForCreatableSelect.current.state.value = newFilterSet; // console.log(refForCreatableSelect.current.state.value); // console.log(refForCreatableSelect.current.props);

    updateFilterSet(visibleFilterSets.length, selectedVariantTab);
    setSelectedFilterSetIndex(visibleFilterSets.length);

    setIsLoadingVisibleFilterSets(false);
  };

  const addFilterToSet = (filterName) => {
    // if(visibleFilterSets[selectedFilterSetIndex].user_defined===true){
    setIsLoadingVisibleFilterSets(true);
    setTabs((prevTabs) => ({
      ...prevTabs,
      [selectedVariantTab]: {
        ...prevTabs[selectedVariantTab],
        filters: {
          ...prevTabs[selectedVariantTab].filters,
          [filterName]: { ...globalTabFilters[filterName] },
        },
      },
    }));

    let tmpGlobalTabFilters = { ...globalTabFilters };
    delete tmpGlobalTabFilters[filterName]; //! is there a way to delete a property without copying it first? NO!
    setGlobalTabFilters(tmpGlobalTabFilters);

    setIsFilterSetModified(true);
    setIsLoadingVisibleFilterSets(false);
    // } else {
    //   toast.error("Can only add to filter-set created by you!");
    // }
  };

  const saveFilterSet = () => {
    if (!!refForCreatableSelect.current) {
      if (!refForCreatableSelect.current.state.value) {
        toast.error("Select a filter-set first!");
      } else if (
        selectedFilterSetIndex !== null &&
        selectedFilterSetIndex !== undefined &&
        visibleFilterSets[selectedFilterSetIndex].user_defined === true
      ) {
        //* update the filterSet first
        setIsLoadingVisibleFilterSets(true);
        let currentFilterSet = {};
        Object.keys(tabs[selectedVariantTab].filters).forEach((filterId) => {
          currentFilterSet[filterId] = {
            ...tabs[selectedVariantTab].filters[filterId],
          }; //! do we need a shallow copy here?
        });

        // console.log(currentFilterSet);
        setVisibleFilterSets((prevVisibleFilterSets) =>
          prevVisibleFilterSets.map((el) =>
            el.value === selectedFilterSetIndex
              ? { ...el, FILTER_DEFINITIONS: currentFilterSet }
              : el
          )
        );
        setIsFilterSetModified(false);
        setIsLoadingVisibleFilterSets(false);
        toast.success("Saved!");
      } else {
        toast.error("Cannot make changes to VaNex filters!");
      }
    }
  };

  const onFilterSetSelectChange = (selectedFilterSet, actionMeta) => {
    if (!!selectedFilterSet) {
      updateFilterSet(selectedFilterSet.value, selectedVariantTab);
      setSelectedFilterSetIndex(selectedFilterSet.value);
    }
  };

  const onFilterMenuOpen = (e, b) => {
    if (
      isFilterSetModified &&
      selectedFilterSetIndex !== null &&
      selectedFilterSetIndex !== undefined &&
      visibleFilterSets[selectedFilterSetIndex].user_defined === true
    ) {
      // if(isFilterSetModified){
      setIsFilterSetModified(false);
      toast(
        (t) => (
          <div className="d-flex flex-row ">
            <div>
              Save your modifications before switching to another filter-set,
              otherwise <b>they will be lost!</b>
            </div>
            <button
              className="btn btn-secondary"
              onClick={() => toast.dismiss(t.id)}
            >
              Dismiss
            </button>
          </div>
        ),
        { duration: 6000 }
      );
    }
  };

  const renderFilterList = () => {
    return (
      <div className="d-flex flex-row justify-content-center">
        <div className="absolute-panel scroll-div">
          <div className="flex-center flex-row mt-1">
            <CreatableSelect
              isClearable
              className="flex-grow-1 mx-2"
              ref={refForCreatableSelect}
              isDisabled={isLoadingVisibleFilterSets}
              isLoading={isLoadingVisibleFilterSets}
              onChange={onFilterSetSelectChange}
              // onChange={(selectedFilterSet, actionMeta) => ( (!!selectedFilterSet) ? setSelectedFilterSetIndex(selectedFilterSet.value) : null )}
              onMenuOpen={onFilterMenuOpen}
              onCreateOption={createNewFilterSet}
              options={visibleFilterSets}
              // defaultValue={visibleFilterSets[selectedFilterSetIndex || 0]}
              placeholder="Preset Filter Sets"
            />
            {/* <i className="far fa-save filter-set-button mx-2 flex-center" onClick={saveFilterSet}></i> */}
            <div
              className="filter-set-button mx-2 flex-center"
              onClick={saveFilterSet}
            >
              <i className="far fa-save"></i>
            </div>
          </div>

          <ul className="list-group small mx-1 my-1">
            {renderFilterInfo(tabs[selectedVariantTab].filters, false)}
            {/* {renderFilterInfo( Object.values(tabs[selectedVariantTab].filters).filter( t => t.visible===true), false )} */}
            {/* {renderFilterInfo( Object.keys(tabs[selectedVariantTab].filters).filter( t => tabs[selectedVariantTab].filters[t].visible===true), false )} */}
            {/* {renderFilterInfo(vanexFilterData, false )} */}
          </ul>

          {(Object.keys(globalTabFilters).length>0)
            ?
            <ul className="list-group small m-1">
              <label className="list-group-item fw-bold d-flex flex-row">
                <span className=" flex-grow-1">Global Filters:</span>
                  <React.Fragment>
                    <span className="fw-normal flex-grow-0 text-dblue cursor-pointer "
                    onClick={() => {setGlobalTabFilters({})}}
                      >Clear <i className="fas fa-trash-alt text-primary"></i></span>
                  </React.Fragment>
              </label>
              {renderFilterInfo(globalTabFilters, true)}
            </ul>
          : null }

          {/* //* show unmodifiable filters! */}
          <ul className="list-group small mx-1 my-1">
            {/* <label className="list-group-item fw-bold d-flex flex-row">Tab Filters:</label> */}
            {Object.values(tabs[selectedVariantTab].filters)
              .filter((t) => t.editable === false)
              .map((item, i) => (
                <label className="list-group-item" key={i}>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      name={item}
                      checked="checked"
                      disabled
                      readOnly
                    />
                    {item.getFilterDescr()}
                  </div>
                </label>
              ))}
            {/* <label className="list-group-item"><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name="gnomAD" checked="checked" disabled  readOnly />{"gnomAD frq. < 0.001"}</div></label> */}
            {filterDescriptions.map((item, i) => (
              <label className="list-group-item" key={i}>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input me-1"
                    type="checkbox"
                    name={item}
                    checked="checked"
                    disabled
                    readOnly
                  />
                  {item}
                </div>
              </label>
            ))}
          </ul>
          {/* <ul className="list-group small m-1">
              { filterDescriptions.map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item}</div></label>
            )}
          </ul> */}

          {/* <div className="flex-center add-new-filter-wrapper mt-5 mb-3">
            <div className="add-new-filter flex-center" onClick={addNewFilter}><i className="fas fa-plus fa-lg"></i></div>
          </div> */}
        </div>
      </div>
    );
  };

  const renderNotesPanel = () => {
    let message = "No notes found.";
    if (currentVariantId === null) {
      message = "No variant selected";
    }

    // let noteFilterFunction = t => (t['IS_SET_BY_OTHERS'] && !!t['VANEX_NOTES']);
    // if(selectedVariantTab==='TAB_SUMMARY' && !!tabs[selectedVariantTab] && !!tabs[selectedVariantTab].filters['MAIN_B'] && !!tabs[selectedVariantTab].filters['MAIN_B'].isApplied){
    //   noteFilterFunction = t => t['IS_SET_BY_OTHERS'];
    // }

    const variant = props.caseData[currentVariantId];
    // const entrezid = variant?.ENTREZ_ID;
    // console.log("variant", variant)
    // console.log(variant.VANEX_NOTES); //! fix this, it may need to get memoized? to have optimized performace

    if (props.project === "training") {
      return (
        <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
          {renderCurrentAnalysisNotes(variant, message)}
        </div>
      );
    }

    return (
      <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
        {renderCurrentAnalysisNotes(variant, message)}
      </div>
    );
  };

  const renderCurrentAnalysisNotes = (variant, message) => {
    return(
      <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        <div className="variant-card-header bg-mid-blue text-light py-0 d-flex flex-row align-items-center">
          <span className="fs-5 fw-bold flex-grow-1">Notes</span>
          
        </div>
        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
          <div className="m-2 d-flex flex-column w-100">
            {!!props.isLoadingNotes ? (
              <div className="d-flex flex-row mt-2">
                <span
                  className="mx-3 spinner-border spinner-border-lg"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="fs-4">Loading...</span>
              </div>
            ) : (
              <>
                {/* { (!!variant && !!variant.VANEX_NOTES && (!!variant.VANEX_TAGS ||  !!variant.VANEX_NOTES['CURRENT_USER_NOTE'] || ( !!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0))) */}
                {!!variant && !!variant.VANEX_NOTES ? (
                  <>
                    <div className="note-bubble d-flex flex-column flex-wrap">
                      <div className="fw-bold me-1 mt-1">
                        {variant.VANEX_TAGS === 1 ||
                        variant.VANEX_TAGS === 2 ? (
                          <i className="fas fa-check-circle fa-lg text-primary me-2"></i>
                        ) : null}
                        {props.userName}
                      </div>
                      <EditableTextArea
                        value={variant.VANEX_NOTES["CURRENT_USER_NOTE"]}
                        updateTextAreaValue={(value) => {
                          if (!!props.updateVariantTableData) {
                            setShouldBlockNavigation(true);
                            props.updateVariantTableData(
                              variant.ROW_INDEX,
                              "VANEX_NOTES",
                              {
                                ...variant.VANEX_NOTES,
                                CURRENT_USER_NOTE: value,
                                UPDATED_AT: new Date(),
                                // ALL_USERS_NOTES: variant.VANEX_NOTES['ALL_USERS_NOTES']
                              }
                            );
                          } else {
                            toast.error("Cannot update this value!");
                          }
                          if (
                            isAutoSavingNotes &&
                            autoSaveNotesCounter < autoSaveNotesInterval
                          ) {
                            setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                          }
                        }}
                      />
                      {/* {(!!variant.VANEX_NOTES['UPDATED_AT']) ? <hr className="m-1 p-0"/> : null} */}
                      <div className="small text-end">
                        {variant.VANEX_NOTES["UPDATED_AT"] &&
                          new Date(
                            variant.VANEX_NOTES["UPDATED_AT"]
                          ).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </div>
                    </div>
                    {!!variant.VANEX_NOTES["ALL_USERS_NOTES"] &&
                    !!variant.VANEX_NOTES["ALL_USERS_NOTES"].length > 0 &&
                    showOtherUsersNotes
                      ? variant.VANEX_NOTES["ALL_USERS_NOTES"]
                          .filter((t) => t["IS_SET_BY_OTHERS"])
                          .map((note, index) => {
                            // variant.VANEX_NOTES['ALL_USERS_NOTES'].map( (note, index) => {
                            if (!note.VANEX_NOTES && !!note.VANEX_TAGS)
                              return (
                                <div
                                  className="d-flex flex-column flex-wrap align-items-center justify-content-center w-100 mb-1"
                                  key={index}
                                >
                                  <div className="fw-bold ">
                                    <i className="fas fa-check-circle fa-lg text-primary my-1 mb-2 mx-2"></i>
                                    {note.USERNAME.split("::")[0]}
                                    {note.USERNAME.split("::").length > 1 ? (
                                      <div className="text-end text-small">
                                        {note.USERNAME.split("::")[1]}{" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            else if (!!note.VANEX_NOTES)
                              return (
                                // <div className="plus-border-radius border-light-blue mb-2 px-2 py-1 d-flex flex-row flex-wrap" key={index}>
                                <div
                                  className="note-bubble d-flex flex-column flex-wrap"
                                  key={index}
                                >
                                  <div className="fw-bold me-1 mt-1">
                                    {!!note.VANEX_TAGS ? (
                                      <i className="fas fa-check-circle fa-lg text-primary me-2"></i>
                                    ) : null}
                                    {note.USERNAME.split("::")[0]}
                                    {note.USERNAME.split("::").length > 1 ? (
                                      <div className="text-end text-small">
                                        {note.USERNAME.split("::")[1]}{" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="note-div px-1">
                                    {note.VANEX_NOTES}
                                  </div>
                                  {/* <hr className="m-1 p-0"/> */}
                                  <div className="small text-end">
                                    {note.UPDATED_AT &&
                                      new Date(note.UPDATED_AT).toLocaleString(
                                        [],
                                        {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        }
                                      )}
                                  </div>
                                </div>
                              );
                            else return <div key={index}>?</div>;
                          })
                      : null}
                  </>
                ) : (
                  <span className="fs-6 text-center">{message}</span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleToggleColumnVisibility = (e, columnId) => {
    let hiddenCols = [...hiddenColumnsIds];
    // TODO: check if this is in the array? this is fine now, but it might be good to check if the checkbox status matches th array!
    if (e.target.checked) {
      // console.log(columnId + ' removed from hiddenColumnsIds');
      hiddenCols = hiddenCols.filter((t) => t !== columnId);
      if (columnOrder.indexOf(columnId) < 0)
        setColumnOrder([...columnOrder, columnId]);
    } else {
      // console.log(columnId + ' pushed to hiddenColumnsIds');
      hiddenCols.push(columnId);
    }
    setHiddenColumnsIds(hiddenCols);
  };

  const getColumnNameFromAccessor = (columnAccessor) => {
    let colDefinition = getParameterCaseInsensitive(
      variantFormatDefinition["column-definitions"],
      columnAccessor
    );
    if (!!colDefinition) {
      let colHeader = colDefinition["header"];
      if (colHeader) {
        return colHeader;
      } else {
        return columnAccessor;
      }
    } else {
      // console.log(colDefinition, columnAccessor);
      return columnAccessor;
    }
  };

  const changeVisibleColumnsToDefaultForExport = () => {
    let defaultExportVisibleColumns = [
      "VANEX_TAGS",
      // "VANEX_NOTES",
      // "VANEX_REVIEWS",
      "INHERITANCE",
      "CHROMOSOME",
      "LOC_START",
      "LOC_END",
      "GENE_SYMBOLS",
      "TYPE",
      "QUAL",
      "READS",
      // "POSITION_2",
      // "CHROMOSOME_2",
      "OMIM_PHENOTYPES",
      "SEG_SIZE",
      // "FILTER",
    ]; // ,'MR_PHENE_PHENOTYPE_TERMS','CLINVAR_CLNSIG','GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX', 'QUAL',
    let hiddenCols = [];
    Object.values(columnData).forEach((column) => {
      if (
        !defaultExportVisibleColumns.includes(column.id) &&
        !column.id.endsWith("_GT") // && !(include_Alt_Percent && column.id.endsWith('_Alt_Percent'))
      ) {
        hiddenCols.push(column.id);
      }
    });
    setHiddenColumnsIds(hiddenCols);
  };

  const [columnNameSearchValue, setColumnNameSearchValue] = useState("");

  const renderColumnList = () => {
    const sampleColNames = Object.keys(sampleColumns).map((i) => i);
    let columnList = Object.values(columnData)
      .filter((t) => t.id !== "SAMPLES" && t.id !== "ROW_INDEX")
      .sort((elA, elB) => {
        if (!sampleColNames.includes(elA.id) && sampleColNames.includes(elB.id))
          return 1;
        if (sampleColNames.includes(elA.id) && !sampleColNames.includes(elB.id))
          return -1;
        if (sampleColNames.includes(elA.id) && sampleColNames.includes(elB.id))
          return (
            sampleColNames.indexOf(elA.id) - sampleColNames.indexOf(elB.id)
          );

        if (!columnOrder.includes(elA.id) && !columnOrder.includes(elB.id))
          return 1;
        if (!columnOrder.includes(elA.id) && columnOrder.includes(elB.id))
          return 1;
        if (columnOrder.includes(elA.id) && !columnOrder.includes(elB.id))
          return -1;
        return columnOrder.indexOf(elA.id) - columnOrder.indexOf(elB.id);
      });
    if (
      columnNameSearchValue !== undefined &&
      columnNameSearchValue !== null &&
      columnNameSearchValue.trim() !== ""
    ) {
      //(columnNameSearchValue.length>0){
      columnList = columnList.filter((t) =>
        String(t["Header"])
          .toLowerCase()
          .includes(columnNameSearchValue.toLowerCase())
      );
    }
    return (
      <div className="absolute-panel scroll-div fw-normal p-2">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary mx-4 my-1 px-3 py-0"
          onClick={changeVisibleColumnsToDefaultForExport}
        >
          Default export columns
        </button>
        <input
          className="w-100 mb-1"
          type="text"
          placeholder="Search Column Names"
          value={columnNameSearchValue}
          onChange={(e) => setColumnNameSearchValue(e.target.value)}
        />
        {columnList.map((column, index) => (
          <div className="no-white-space-wrap" key={index}>
            {!!showPMFPrefix && !!sampleColumns[column.id] ? (
              !!getTypeOfColumnsInPMFList(column) ? (
                // <button type="button" className="btn btn-sm btn-outline-secondary m-0 ms-1 p-0 px-1" data-bs-toggle="modal" data-bs-target="#refForChangeSampleTypeModal">{column.sampleType}</button>
                <button
                  type="button"
                  style={{ minWidth: "2rem" }}
                  className="btn btn-sm btn-outline-secondary m-0 me-1 mb-1 p-0 px-1"
                  onClick={() => sampleTypeModal.show()}
                >
                  {getTypeOfColumnsInPMFList(column)}
                </button>
              ) : (
                <span style={{ marginRight: "2.25rem" }}></span>
              )
            ) : (
              <></>
            )}
            <label>
              <input
                type="checkbox"
                className="me-1"
                checked={!hiddenColumnsIds.includes(column.id)}
                onChange={(e) => handleToggleColumnVisibility(e, column.id)}
                disabled={
                  !hiddenColumnsIds.includes(column.id) &&
                  hiddenColumnsIds.length >= Object.keys(columnData).length - 3
                }
              />
              {column.Header}
            </label>
          </div>
        ))}
        {columnNameSearchValue.length > 0 ? (
          <div
            className="text-dblue cursor-pointer text-center"
            onClick={() => setColumnNameSearchValue("")}
          >
            +{" "}
            {Object.values(columnData).length -
              Object.values(columnList).length}{" "}
            unmatched (show all)
          </div>
        ) : null}
      </div>
    );
  };

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  });
  useEffect(() => {
    props.setShouldBlockNavigation(shouldBlockNavigation);
  }, [shouldBlockNavigation]);

  const renderVariantTab = (tabId) => {
    // console.log(props.currentAnalysisInfo);
    if(!mainUseEffectsIsDone){
      return(<div className="variant-viewer-split-pane d-flex my-5 justify-content-center">
          <span className="mx-3 spinner-border" role="status" aria-hidden="true"></span>
          <span className="fs-3">Loading...</span>
        </div>)
    }

    return(
      <div className="variant-viewer-split-pane">
        <Prompt
          when={shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={345}>
          {/* <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}> */}
          <div className="show-overflow absolute-panel d-flex flex-column mb-1 bg-white">

            <VariantTable
              // setSortBy={props.setSortBy}
              // initialSortBy={props.initialSortBy}
              initialSortBy={initialSortBy}
              trigerExportPreparation={triggerExportPreparation}
              preparedDataFromVariantTable={(values) => {setPreparedDataFromVariantTable(values); setTriggerExportPreparation(false)}}
              updateVariantTableData={(variantIndex, columnId, value) => { 
                if(!!props.updateVariantTableData){ 
                  setShouldBlockNavigation(true);
                  props.updateVariantTableData(variantIndex, columnId, value);
                } else {toast.error('Cannot update this value!')}
                if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                  setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                }
              }}
              getTabName={() => selectedVariantTab}
              // getCaseID={() => props.currentCaseInformation.CASE_ID}
              // sendFeedback={sendDenovoAssessment}
              setColumnsSpec={(colId, colWidth) => Object.values(columnData).find(t => t.id===colId).setColWidth(colWidth) }
              hiddenColumnsIds={hiddenColumnsIds}
              reportCurrentPageIndex={(pind) => setLatestTablePageIndex(pind)}
              initialPageIndex={latestTablePageIndex}
              setDataForFilterSelectionModal={setDataForFilterSelectionModal}
              // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
              PMFSampleColNames={getSampleColumnsProbandAtFirst()}
              currentVariantId={currentVariantId} 
              displayVariantInfo={ (variantId) => setCurrentVariantId(variantId)}
              variantFilterObj={ {...tabs[selectedVariantTab].filters, ...globalTabFilters} }
              columns={columnData} data={variantData}
            />
          </div>
          <div className="inner-tabs-wrapper">
            <Tabs>
              <TabList>
                <Tab><span>SV Info</span></Tab>
              </TabList>
              <TabPanel>{renderVariantInfo()}</TabPanel>
              {/* <TabPanel><h1>Dynamic Plots</h1></TabPanel> */}
              
            </Tabs>
          </div>
        </SplitPane>
      </div>
    );
  };

  const renderFiltersAndColumnsTabs = () => {
    return(
      <Tabs>
        <TabList>
          <Tab><span>Filters</span></Tab>
          <Tab><span>Columns</span></Tab>
          {/* <Tab><span>Pathways</span></Tab> */}
        </TabList>
        <TabPanel>{renderFilterList()}</TabPanel>
        <TabPanel>{renderColumnList()}</TabPanel>
        {/* <TabPanel>{renderPathwayList()}</TabPanel> */}
      </Tabs>
    );
  }

  const onSelectedTabChange = (index, lastIndex, event) => {
    // console.log(event.target.innerText);
    if(!!refForCreatableSelect.current){
      refForCreatableSelect.current.state.value = null;
    }
    setLatestTablePageIndex(0);
    setCurrentVariantId(null);
    // setNotesForCurrentVariant([]);
    setSelectedVariantTab(Object.keys(tabs)[index]);
    setSelectedFilterSetIndex(null);
  }

  const isNumericColumn = (columnId) => {
    let tmpList = props.caseData.filter(t => t[columnId]!==null);
    if(!!tmpList[0]){
      if(tmpList[0][columnId]!==undefined && tmpList[0][columnId]!==null){
        return(typeof(tmpList[0][columnId])=='number');
      }
    }
    return false;
  }

  const review_code_to_text = (revCode, returnNullForNotFound=false, returnLowerCase=false) => {
    let tmpOption = Object.values(ReviewOptions).find(l => l.value===revCode);
    // let tmpOption = Object.values(ReviewOptions).find(l => String(l.value).toLowerCase() === String(revCode).toLowerCase());
    if(!!tmpOption && !!tmpOption.label){
      if(returnLowerCase)
        return(String(tmpOption.label).toLowerCase());
      else
        return(tmpOption.label);
    } else {
      return( (returnNullForNotFound ? null : '' ) );
    }
  }

  const setDataForFilterSelectionModal = (e, selectedColumn) => {
    console.log("---------", selectedColumn);
    console.log(isNumericColumn(selectedColumn.id));
    if (selectedColumn.id === "VANEX_TAGS") {
      toast.error("Cannot filter " + selectedColumn.Header);
      return;
    }
    setFilterSelectionTitle(`Filtering ${selectedColumn.Header}`);
    let initValues = {
      columnName: selectedColumn.id,
      value: "",
      valueIsList: false,
      separateValueListBy: " ",
      minValue: "",
      maxValue: "",
      includeNullValues: true,
      selTerms: [],
      filterBy: "UserProvidedValue",
      selectAllStatus: UNCHECKED,
    };

    // let initValues = { value: 'z', minValue: 0, maxValue:0 }
    // setFilterSelectionFormik(<Formik className="" enableReinitialize={true} initialValues={initValues}
    // onSubmit={(fields) => {
    //     console.log(fields);
    //     filterSelectionModal.hide();
    //   }}>
    //     {({ values, setFieldValue }) => (
    //     <Form id={}>
    //       {/* { console.log(values)} */}
    //       {/* <CustomerPopup
    //           showModal={showModal}
    //           close={() => toggleCustomerModal()}
    //           onSelect={data => onSelectCustomerHandler(data, setFieldValue)}
    //         /> */}
    //       <div className="modal-body">
    //         <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
    //         <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
    //       </div>
    //     <div className="modal-footer ">
    //       <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i> Apply</button>
    //       <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
    //     </div>
    //     </Form>)}
    //   </Formik>);
    //   filterSelectionModal.show();
    // return;

    if (isNumericColumn(selectedColumn.id)) {
      //? ------------------- filtering column with NUMERIC values  ---------------------------

      if (!!globalTabFilters[selectedColumn.id]) {
        if (
          !!globalTabFilters[selectedColumn.id]["minValue"] &&
          !!globalTabFilters[selectedColumn.id]["maxValue"]
        ) {
          initValues["minValue"] =
            globalTabFilters[selectedColumn.id]["minValue"];
          initValues["maxValue"] =
            globalTabFilters[selectedColumn.id]["maxValue"];
        }
      }

      const getFilterDescriptionValue = (
        minValue,
        maxValue,
        includeNullValues
      ) => {
        let hasMinValue = !!minValue || minValue === 0;
        let hasMaxValue = !!maxValue || maxValue === 0;
        let nullValueDesc = includeNullValues ? " (Include Blanks)" : "";
        if (hasMinValue && !hasMaxValue)
          return ` >= "${minValue}"${nullValueDesc}`;
        if (!hasMinValue && hasMaxValue)
          return ` <= "${maxValue}"${nullValueDesc}`;
        if (!hasMinValue && !hasMaxValue) return `${nullValueDesc}`;
        return ` = "${minValue} to ${maxValue}"`;
      };

      setFilterSelectionFormik(
        <Formik
          className=""
          enableReinitialize={true}
          initialValues={initValues}
          onSubmit={(fields) => {
            // console.log(fields);
            setGlobalTabFilters((prevGlobalTabFilters) => ({
              ...prevGlobalTabFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: getFilterDescriptionValue(
                  fields.minValue,
                  fields.maxValue,
                  fields.includeNullValues
                ),
                minValue: fields.minValue,
                maxValue: fields.maxValue,
                operation: "=",
                visible: true,
                editable: true, // tabFilter: false,
                getFilterDescr: function () {
                  return this.label + this.value;
                },
                // getFilterDescr: function(){ return(this.label + ' ' + this.operation + ' ' + this.value); },
                filterFunction: function () {
                  let hasMinValue = !!fields.minValue || fields.minValue === 0;
                  let hasMaxValue = !!fields.maxValue || fields.maxValue === 0;
                  if (hasMinValue && !hasMaxValue)
                    return (t) =>
                      t[selectedColumn.id] !== null &&
                      t[selectedColumn.id] !== undefined
                        ? t[selectedColumn.id] >= fields.minValue
                        : fields.includeNullValues
                        ? true
                        : false;
                  if (!hasMinValue && hasMaxValue)
                    return (t) =>
                      t[selectedColumn.id] !== null &&
                      t[selectedColumn.id] !== undefined
                        ? t[selectedColumn.id] <= fields.maxValue
                        : fields.includeNullValues
                        ? true
                        : false;
                  if (!hasMinValue && !hasMaxValue)
                    return (t) =>
                      t[selectedColumn.id] !== null &&
                      t[selectedColumn.id] !== undefined
                        ? false
                        : fields.includeNullValues
                        ? true
                        : false;
                  return (t) =>
                    t[selectedColumn.id] !== null &&
                    t[selectedColumn.id] !== undefined
                      ? t[selectedColumn.id] >= fields.minValue &&
                        t[selectedColumn.id] <= fields.maxValue
                      : fields.includeNullValues
                      ? true
                      : false;
                },
                isApplied: true,
              },
            }));
            filterSelectionModal.hide();
          }}
        >
          <Form>
            <div className="modal-body">
              <table className="">
                <tbody>
                  <tr>
                    <td>
                      <label className="">
                        <br />
                        {selectedColumn.Header}
                      </label>
                    </td>
                    <td>
                      <label className="">
                        <br />
                        <i className="fas fa-equals"></i>
                      </label>
                    </td>
                    <td>
                      <label className="form-check-label">
                        Min
                        <Field
                          className="form-control"
                          type="text"
                          name="minValue"
                          placeholder=""
                          aria-label=""
                        />
                      </label>
                    </td>
                    <td>
                      <label className="form-check-label">
                        Max
                        <Field
                          className="form-control"
                          type="text"
                          name="maxValue"
                          placeholder=""
                          aria-label=""
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <label className="mt-2 w-100 d-flex flex-column ms-1 align-items-center">
                <div>
                  <Field
                    className="mx-1"
                    type="checkbox"
                    name="includeNullValues"
                    placeholder=""
                    aria-label=""
                  />
                  Include Blanks
                </div>
              </label>
            </div>
            <div className="modal-footer ">
              <button className="btn btn-primary" type="submit">
                <i className="fas fa-filter me-2"></i>Apply Filter
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => filterSelectionModal.hide()}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      );
    } else {
      //? ------------------- filtering column with TEXT values ---------------------------

      if (!!globalTabFilters[selectedColumn.id]) {
        // debugger;
        if (!!globalTabFilters[selectedColumn.id]["value"]) {
          initValues["value"] = globalTabFilters[selectedColumn.id]["value"];
        }
        if (!!globalTabFilters[selectedColumn.id]["filterBy"]) {
          initValues["filterBy"] =
            globalTabFilters[selectedColumn.id]["filterBy"];
        }
        if (!!globalTabFilters[selectedColumn.id]["selTerms"]) {
          initValues["selTerms"] =
            globalTabFilters[selectedColumn.id]["selTerms"];
        }
      }

      //? ------------------- collect unique values in an array ---------------------------
      let tmpObj = {};
      let tmpval;
      // Object.values(props.caseData).forEach((variantRecord) => {
      Object.values(variantData).forEach((variantRecord) => {
        // tmpObj[variantRecord[selectedColumn.id]] = ((!!variantRecord[selectedColumn.id]) ? variantRecord[selectedColumn.id] : "__Blank__");
        if (selectedColumn.id === "VANEX_NOTES") {
          tmpObj[variantRecord[selectedColumn.id]["CURRENT_USER_NOTE"]] =
            variantRecord[selectedColumn.id]["CURRENT_USER_NOTE"];
        } else if (selectedColumn.id === "VANEX_REVIEWS") {
          tmpObj[variantRecord[selectedColumn.id]["REVIEW_VALUE"]] = null;
          tmpval = Object.values(ReviewOptions).find(
            (l) => l.value === variantRecord[selectedColumn.id]["REVIEW_VALUE"]
          );
          // console.log(tmpval);
          if (!!tmpval && !!tmpval.label) {
            tmpObj[variantRecord[selectedColumn.id]["REVIEW_VALUE"]] =
              tmpval.label;
          }
        } else {
          tmpObj[variantRecord[selectedColumn.id]] =
            variantRecord[selectedColumn.id];
        }
      });
      let selColUniqueValues = Object.values(tmpObj)
        .map((k) => k)
        .sort()
        .sort((elA, elB) => Number(!elA) - Number(!elB));
      // console.log(selColUniqueValues);

      setFilterSelectionFormik(
        <Formik
          className=""
          enableReinitialize={true}
          initialValues={initValues}
          onSubmit={(fields) => {
            //! Equals, Does not equal, Contains, Does not contain, Begins with, Ends with,
            // console.log(fields);
            let operation = "contains";
            // let useSelTerms = fields.filterBy === 'SelectedTerms';
            let myFilterFunction;
            if (
              fields.filterBy === "SelectedTerms" ||
              fields.valueIsList === true
            ) {
              operation = "=";
              let selectedTerms;
              let multiValuesProvided =
                fields.valueIsList === true &&
                fields.filterBy !== "SelectedTerms";
              if (multiValuesProvided) {
                // selectedTerms = fields.value.split(fields.separateValueListBy);
                selectedTerms = String(fields.value)
                  .toLowerCase()
                  .split(fields.separateValueListBy);
              } else {
                selectedTerms = fields.selTerms;
              }
              // let selectedTerms = fields.selTerms.map( (term) => {
              //   if (term==='__Blank__'){
              //     return(null);
              //   }
              //   else{
              //     return(term);
              //   }
              // });
              // let includeNullValuesInFilter = fields.selTerms.includes("__Blank__");
              let includeNullValuesInFilter =
                fields.selTerms.includes(null) || fields.selTerms.includes("");
              myFilterFunction = function () {
                //* if multi values are provided as a list use toLowerCase first before comparing! otherwise they need to match exactly (it is case sensitive)
                if (selectedColumn.id === "VANEX_NOTES") {
                  if (multiValuesProvided)
                    return (t) =>
                      !!t[selectedColumn.id]
                        ? selectedTerms.indexOf(
                            String(
                              t[selectedColumn.id]["CURRENT_USER_NOTE"]
                            ).toLowerCase()
                          ) !== -1
                        : includeNullValuesInFilter;
                  else
                    return (t) =>
                      !!t[selectedColumn.id]
                        ? selectedTerms.indexOf(
                            t[selectedColumn.id]["CURRENT_USER_NOTE"]
                          ) !== -1
                        : includeNullValuesInFilter;
                } else if (selectedColumn.id === "VANEX_REVIEWS") {
                  // if(fields.valueIsList===true)
                  //   selectedTerms = String(fields.value).toLowerCase().split(fields.separateValueListBy);
                  return (t) =>
                    !!t[selectedColumn.id]
                      ? selectedTerms.indexOf(
                          review_code_to_text(
                            t[selectedColumn.id]["REVIEW_VALUE"],
                            true,
                            multiValuesProvided
                          )
                        ) !== -1
                      : includeNullValuesInFilter;
                } else {
                  if (multiValuesProvided)
                    return (t) =>
                      !!t[selectedColumn.id]
                        ? selectedTerms.indexOf(
                            String(t[selectedColumn.id]).toLowerCase()
                          ) !== -1
                        : includeNullValuesInFilter;
                  else
                    return (t) =>
                      !!t[selectedColumn.id]
                        ? selectedTerms.indexOf(t[selectedColumn.id]) !== -1
                        : includeNullValuesInFilter;
                }
              };
            } else {
              myFilterFunction = function () {
                if (selectedColumn.id === "VANEX_NOTES") {
                  return (t) =>
                    !!t[selectedColumn.id]
                      ? String(t[selectedColumn.id]["CURRENT_USER_NOTE"])
                          .toLowerCase()
                          .includes(fields.value.toLowerCase())
                      : false;
                } else if (selectedColumn.id === "VANEX_REVIEWS") {
                  return (t) =>
                    !!t[selectedColumn.id]
                      ? String(
                          review_code_to_text(
                            t[selectedColumn.id]["REVIEW_VALUE"],
                            false
                          )
                        )
                          .toLowerCase()
                          .includes(fields.value.toLowerCase())
                      : false;
                  // return( (t) => ( (!!t[selectedColumn.id]) ? false : false ));
                } else {
                  return (t) =>
                    !!t[selectedColumn.id]
                      ? String(t[selectedColumn.id])
                          .toLowerCase()
                          .includes(fields.value.toLowerCase())
                      : false;
                  // return( (t) => {
                  //   if(!!t[selectedColumn.id])
                  //     console.log(String(t[selectedColumn.id]));
                  //   return(true)
                  //   // return( (!!t[selectedColumn.id]) ? t[selectedColumn.id].toLowerCase().includes(fields.value.toLowerCase()) : false )
                  // });
                }
              };
            }

            setGlobalTabFilters((prevGlobalTabFilters) => ({
              ...prevGlobalTabFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: fields.value,
                operation: `${operation}`,
                visible: true,
                editable: true, // tabFilter: false,
                selTerms: fields.selTerms,
                filterBy: fields.filterBy,
                getFilterDescr: function () {
                  let fieldValue = `"${this.value}"`;
                  if (this.filterBy === "SelectedTerms") {
                    fieldValue = `[${this.selTerms.join("], [")}]`;
                    // if(this.selTerms.length > 3){fieldValue = "[...]";}
                    // return(`"${fieldValue}"`)
                  }
                  return this.label + " " + this.operation + " " + fieldValue;
                },
                filterFunction: myFilterFunction,
                isApplied: true,
              },
            }));

            filterSelectionModal.hide();
          }}
        >
          {({ errors, values, touched, setValues }) => (
            <Form>
              <div className="modal-body">
                <div className="d-flex flex-row w-100">
                  <div className="d-flex flex-column flex-grow-1">
                    <label className="">
                      <Field
                        name="filterBy"
                        className="form-check-input me-2"
                        type="radio"
                        value="UserProvidedValue"
                        placeholder=""
                        aria-label=""
                      />
                      {selectedColumn.Header}
                      <span className="fw-bold mx-1">Contains</span>
                    </label>
                    <label className="form-check-label flex-grow-1">
                      <Field
                        className="form-control w-100"
                        type="text"
                        name="value"
                        placeholder=""
                        aria-label=""
                        onFocus={(e) => {
                          values.filterBy = "UserProvidedValue";
                        }}
                      />
                    </label>
                  </div>
                  <label className="d-flex flex-row ms-1 align-items-center flex-grow-0">
                    <Field
                      className="mx-1"
                      name="valueIsList"
                      type="checkbox"
                    />
                    <div className={values.valueIsList ? "" : "text-muted"}>
                      multiple values <br />
                      separated by
                      <Field
                        className="mx-1"
                        disabled={!values.valueIsList}
                        name="separateValueListBy"
                        type="text"
                        style={{ maxWidth: "1.5rem" }}
                      />
                    </div>
                  </label>
                </div>
                <hr />
                <label className="">
                  <Field
                    name="filterBy"
                    className="form-check-input me-2"
                    type="radio"
                    value="SelectedTerms"
                    placeholder=""
                    aria-label=""
                  />
                  {selectedColumn.Header}
                  <span className="fw-bold mx-1">matches</span>
                </label>
                <div
                  className="ist-group list-group-flush border border-secondary border-1 m-1 show-overflow l"
                  style={{ maxHeight: "40vh" }}
                >
                  <label className="list-group-item">
                    <IndeterminateCheckbox
                      className="form-check-input me-1"
                      name="select-all"
                      value={values.selectAllStatus}
                      onFocus={(e) => {
                        values.filterBy = "SelectedTerms";
                      }}
                      onChange={(e) => {
                        let chboxStatus = INDETERMINATE;
                        let selectedTerms = [];
                        if (e.target.checked) {
                          selColUniqueValues.forEach((term) =>
                            selectedTerms.push(term)
                          );
                          chboxStatus = CHECKED;
                        } else {
                          chboxStatus = UNCHECKED;
                        }
                        setValues({
                          ...values,
                          selectAllStatus: chboxStatus,
                          selTerms: selectedTerms,
                        });
                      }}
                    />
                    Select All
                  </label>
                  <FieldArray
                    name="selTerms"
                    render={(arrayHelpers) => (
                      <React.Fragment>
                        {selColUniqueValues.map((tag, ind) => (
                          <label className="list-group-item" key={ind}>
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              name={
                                tag !== null && tag !== undefined && tag !== ""
                                  ? tag.toString()
                                  : "__Blank__"
                              }
                              checked={values.selTerms.includes(tag)}
                              onFocus={(e) => {
                                values.filterBy = "SelectedTerms";
                              }}
                              onChange={(e) => {
                                let chboxStatus = INDETERMINATE;
                                let selectedTerms = [...values.selTerms];
                                if (values.selTerms.length === 0) {
                                  chboxStatus = UNCHECKED;
                                }
                                if (e.target.checked) {
                                  if (values.selTerms.length === 0) {
                                    chboxStatus = INDETERMINATE;
                                  }
                                  if (
                                    selColUniqueValues.length ===
                                    values.selTerms.length + 1
                                  ) {
                                    chboxStatus = CHECKED;
                                  }
                                  // arrayHelpers.push(tag);
                                  selectedTerms.push(tag);
                                } else {
                                  if (values.selTerms.length - 1 === 0) {
                                    chboxStatus = UNCHECKED;
                                  }
                                  const removeIndex =
                                    values.selTerms.indexOf(tag);
                                  // arrayHelpers.remove(removeIndex);
                                  // let removeIndex = selectedTerms.map(item => item.id).indexOf(tag);
                                  ~removeIndex &&
                                    selectedTerms.splice(removeIndex, 1); //! <= this is a nice way of doing this: (removeIndex >= 0) && array.splice(removeIndex, 1);
                                }
                                setValues({
                                  ...values,
                                  selectAllStatus: chboxStatus,
                                  selTerms: selectedTerms,
                                });
                              }}
                            />
                            {/* <span>{ (typeof(tag)==='boolean' ? ( (tag) ? 'Checked' : 'Unchecked') : (!!tag ? tag : "__Blank__") )}</span> */}
                            {/* <span>{(!!tag) ? (typeof(tag)==='boolean' ? 'Checked' : tag ) : "__Blank__"}</span> */}
                            <span>
                              {tag !== null && tag !== undefined && tag !== ""
                                ? tag
                                : "__Blank__"}
                            </span>
                          </label>
                        ))}
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
              <div className="modal-footer ">
                <button className="btn btn-primary" type="submit">
                  <i className="fas fa-filter me-2"></i>Apply Filter
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => filterSelectionModal.hide()}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    filterSelectionModal.show();
    return;
  };

  function handleSampleTypeChange(fields) {
    if (
      (fields.Proband !== "None" &&
        fields.Mother !== "None" &&
        fields.Father !== "None" &&
        fields.Proband !== fields.Mother &&
        fields.Mother !== fields.Father &&
        fields.Proband !== fields.Father) ||
      // && (fields.Proband !== '')  && (fields.Mother !== '')  && (fields.Father !== '')
      (fields.Proband !== "None" &&
        fields.Mother !== "None" &&
        fields.Father === "None" &&
        fields.Proband !== fields.Mother) ||
      (fields.Proband !== "None" &&
        fields.Mother === "None" &&
        fields.Father !== "None" &&
        fields.Proband !== fields.Father) ||
      (fields.Proband !== "None" &&
        fields.Mother === "None" &&
        fields.Father === "None")
    ) {
      setPMFSampleColNames([fields.Proband, fields.Mother, fields.Father]);
      const sampleColumnNames = Object.keys(sampleColumns).map((i) => i);
      if (!!sampleColumnNames && sampleColumnNames.length > 0) {
        setInitialColumnData((prevColumnData) =>
          prevColumnData.map((colInfo) => {
            // let colHeader=colInfo.Header;
            let colAccessor = colInfo.id;
            if (sampleColumnNames.includes(colInfo.id))
              return {
                ...colInfo,
                Header:
                  getTypeOfColumnsInPMFList(colInfo, false, true, [
                    fields.Proband,
                    fields.Mother,
                    fields.Father,
                  ]) + colInfo.id,
              };
            // colHeader=getTypeOfColumnsInPMFList(colInfo,false, true, [fields.Proband, fields.Mother, fields.Father]) + colInfo.id;
            if (
              colAccessor.endsWith("_GT") ||
              colAccessor.endsWith("_Alt_Percent") ||
              colAccessor.endsWith("_GQ") ||
              colAccessor.endsWith("_AD") ||
              colAccessor.endsWith("_RD") ||
              colAccessor.endsWith("_TD") ||
              colAccessor.endsWith("_PL") ||
              colAccessor.endsWith("_DP") ||
              colAccessor.endsWith("_SB") ||
              colAccessor.endsWith("_GT_RAW")
            )
              // colHeader=colAccessor;//`${getSampleLabel(colAccessor.slice(0,-3))}: ${colAccessor}`;
              return { ...colInfo, Header: colAccessor };
            return colInfo;
          })
        );
      }
      toast.success("Success!"); // toast.success('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
      setRefresh((prevState) => !prevState);
      sampleTypeModal.hide();
    } else {
      toast.error("Not a valid combination!");
    }
  }

  //? --------------------------------------------- Main Render ----------------------------------
  // console.log("render...");
  // console.log(tabs);
  // console.log(selectedFilterSetIndex);
  return (
    <React.Fragment>
      <SplitPane className={" bottom-tab-panel " + ( (props.onlyShowOneTab) ? " plus-border-radius " : "" )}
        split="vertical" minSize={100} maxSize={-100} defaultSize={280} >
        {(!!props.currentCaseNotes) ?
          <div className="variant-viewer-split-pane">
            <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={585} >
              <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
                {renderFiltersAndColumnsTabs()}
              </div>
              <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
                {renderNotesPanel()}
              </div>
            </SplitPane>
          </div>
        :
          <div className="sidebar-splitpane-panel">
            {renderFiltersAndColumnsTabs()}
          </div>
        }
        <div className="sidebar-splitpane-panel absolute-panel">
          <Tabs defaultIndex={Object.keys(tabs).indexOf(selectedVariantTab)} onSelect={onSelectedTabChange} >
            <TabList style={{paddingRight:'7rem'}}>
              {Object.values(tabs).map( (tabName, index) => <Tab key={index}><span>{tabName.label}</span></Tab>)}
              <div className="tab-menu-button-group d-flex flex-row ps-1 pt-1 align-items-center " >
                {/* <Dropdown as={ButtonGroup} size="sm" className="m-0 p-0">
                  <Button variant=""  className="m-0 p-0 text-light">
                  
                  <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={saveNotes}>
                      <i className="fas fa-file-alt fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Save Notes</span>
                      {(isSavingNotes) ? 
                      <div className="progress export-progressbar">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${savingNotesProgressbarValue}%`}} aria-valuenow={savingNotesProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      : null}
                    </div>

                  </Button>

                  <Dropdown.Toggle split variant="" className="text-light" id="dropdown-split-basic" />

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <span className="ms-1 " style={{cursor:'default'}}>Started at: <span className="fw-bold">{analysisStartedAt}</span></span> */}
                {(canSaveNotes)
                ?
                  <>
                    <div className="m-0 ms-1 p-0 px-1">
                      <label className="fs-6" >
                        <input className="mx-1" type="checkbox" name="use1000separatorcheckbox"
                          onChange={(e) => {setUse1000Separator(e.target.checked)}}
                          checked={use1000Separator} 
                        />
                        Use 1000 Separator
                      </label>
                    </div>
                    <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={saveNotes}>
                      {(isSavingNotes) ? 
                        <>
                          <span className="mx-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {/* <i className="fas fa-circle fs-6"></i> */}
                          <span className="fs-6 fw-bold ms-1 p-0">Saving...</span>
                          <div className="progress export-progressbar">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${savingNotesProgressbarValue}%`}} aria-valuenow={savingNotesProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </>
                      :
                        (numberOfNotesSaved!==null && numberOfNotesSaved!==undefined) ? 
                          <>
                            <i className="fas fa-check-double green-lighter fs-6"></i>
                            <span className="fs-6 fw-bold ms-1 p-0">{numberOfNotesSaved} Notes Saved!</span>
                            {/* // TODO: if added review to CNV, bring this message back! <span className="fs-6 fw-bold ms-1 p-0">{numberOfNotesSaved} Notes &amp; {numberOfReviewSaved} Review Saved!</span> */}
                          </>
                        :
                          <>
                            <i className="fas fa-file-alt fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Save Notes</span>
                          </>
                      }
                    </div>
                    <Dropdown align="end" onSelect={notesOptions}>
                      <Dropdown.Toggle variant="" id="notesOptions-dropdown" className="tab-menu-button dropdown-toggle m-0 p-0 py-2 px-1 d-flex flex-row align-items-center">
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="export-menu m-0" >
                        {/* <Dropdown.Item as={() => (<div className=""><input type="checkbox" className="mx-2" checked={isAutoSavingNotes}  readOnly />Auto Save Notes</div>)
                        } eventKey="summary"></Dropdown.Item> */}
                        <Dropdown.Item as="button" eventKey="hideOtherUsersNotes"><div className=""><input type="checkbox" className="mx-2" checked={!showOtherUsersNotes}  readOnly />Hide Other Users' Notes</div></Dropdown.Item>
                        <Dropdown.Item as="button" eventKey="autoSavingNotes" ><div className=""><input type="checkbox" className="mx-2" checked={isAutoSavingNotes}  readOnly />Auto Save Notes</div></Dropdown.Item>
                        <Dropdown.Item as={() => (<div className={`ms-5 d-flex ${(!isAutoSavingNotes) ? 'text-muted' :'' }`}>
                          - every<input type="number" 
                            min="1" step="1" 
                            className="mx-2" style={{maxWidth:'2.5rem', maxHeight:'1.6rem'}}
                            disabled={!isAutoSavingNotes}
                            defaultValue={autoSaveNotesInterval}
                            onChange={e => setAutoSaveNotesInterval(e.target.value)}
                          />
                          </div>)
                        } eventKey="summary"></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                : null }
                <Dropdown align="end " onSelect={exportSnvAsXlsx}>
                  <Dropdown.Toggle variant="" id="export-dropdown" className="tab-menu-button m-0 ms-1 p-0 px-2 d-flex flex-row align-items-center">
                    <div>
                      <i className="fas fa-download fs-5"></i><span className="fs-6 fw-bold mx-1 p-0">Export</span>
                      {(isExporting) ? 
                      <div className="progress export-progressbar">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      : null}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="export-menu m-0" >
                    <Dropdown.Item as="button" eventKey="clipboard">to Clipboard</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="summary">Summary Tab Only</Dropdown.Item>
                    {/* <Dropdown.Item as="button" eventKey="noOriginalData">Exclude 'All SNVs' Tab</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="all">Complete Dataset</Dropdown.Item> */}
                    {/* <Dropdown.Item as="button" eventKey="all" onClick={() => exportSnvAsXlsx('all')} >Complete Dataset</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <span onClick={exportSnvAsXlsx}>
                  <i className="fas fa-download fs-5"></i><span className="fs-5 fw-bold mx-1 p-0">Export</span>
                  {(isExporting) ? 
                  <div className="progress export-progressbar">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  : null}
                </span> */}
              </div>
            </TabList>
            {Object.keys(tabs).map( (tabId, indx) => <TabPanel key={indx}>{renderVariantTab(tabId)}</TabPanel>)}
          </Tabs>
        </div>
      </SplitPane>
      {/* //? --------------------------------------------- Modal ---------------------------------- */}
      <div className="modal fade" ref={refForFilterSelectionModal} tabIndex="-1" aria-labelledby="refForFilterSelectionModal" aria-hidden="true">
        {/* <div className="modal-dialog modal-sm"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{filterSelectionTitle}</h5>
              <button type="button" className="btn-close" onClick={() => filterSelectionModal.hide()} aria-label="Close"></button>
            </div>
            {filterSelectionFormik}
          </div>
        </div>
      </div>
      {/* END Modal */}

      {/* //? --------------------------------------------- SampleType Modal ---------------------------------- */}
      <div className="modal fade" ref={refForChangeSampleTypeModal} tabIndex="-1" aria-labelledby="changeSampleTypeLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changeSampleTypeLabel">Change Sample Types</h5>
              <button type="button" className="btn-close" onClick={() => sampleTypeModal.hide()}  aria-label="Close"></button>
            </div>
            <Formik className=""
              enableReinitialize={true}
              initialValues={{ Proband: PMFSampleColNames[0] || 'None', Mother: PMFSampleColNames[1] || 'None', Father: PMFSampleColNames[2] || 'None' }}
              onSubmit={handleSampleTypeChange}
            >
            {({ errors, values, touched, setValues, setFieldValue }) => {
              let selectOptions = Object.keys(sampleColumns).map(i => ({value: i, label:i}) );
              selectOptions=[...selectOptions,{value: 'None', label:'None'}];
              return(
                <Form>
                  <div className="modal-body d-flex justify-content-center ">

                    <table className="change-sample-type-table w-100">
                      <thead>
                        <tr>
                          <th className="">Sample Type</th>
                          <th className="w-100">Sample ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="mx-3">Proband</label> 
                          </td>
                          <td className="d-flex">
                            <Select className="w-100" 
                              name="Proband"
                              options={selectOptions}
                              value={{value: values.Proband, label:values.Proband}}
                              onChange={selectedOption => setFieldValue('Proband', selectedOption.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="mx-3">Mother</label> 
                          </td>
                          <td className="d-flex">
                            <Select className="w-100"
                              name="Mother"
                              options={selectOptions}
                              value={{value: values.Mother, label:values.Mother}}
                              onChange={selectedOption => setFieldValue('Mother', selectedOption.value)}
                            />
                          </td>
                        </tr>
                          <tr>
                            <td>
                              <label className="mx-3">Father</label> 
                            </td>
                            <td className="d-flex">
                              <Select className="w-100"
                                name="Father"
                                options={selectOptions}
                                value={{value: values.Father, label:values.Father}}
                                onChange={selectedOption => setFieldValue('Father', selectedOption.value)}
                              />
                            </td>
                          </tr>
                      </tbody>
                    </table>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => sampleTypeModal.hide()}>Close</button>
                    <button className="btn btn-primary" type="submit" ><i className="fas fa-save me-2"></i> Save changes</button>
                  </div>
                </Form>
              )
            }}
            </Formik>
          </div>
        </div>
      </div>
      {/* END Modal */}
    </React.Fragment>
  );
}

export default SvViewer;
